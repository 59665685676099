import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../utils/app-insights";
import { env } from "../../utils/env";
import { AppLoadingIndicator } from "../app-root/app-loading-indicator";

export const ExternalPage: React.FC = () => {
	const { pathname, search } = useLocation();

	const urlSearchParams = new URLSearchParams(search);
	urlSearchParams.set("forceClassic", "true");

	useEffect(() => {
		appInsights.trackPageView({
			name: "External",
			properties: {
				pathname,
			},
		});

		// We intentionally altered the url by adding `?forceClassic=true` before navigating. Otherwise, the browser back button
		// will skip the entry in history. This behavior can only be validated in UAT environment where modern and classic HITS are
		// running under the same host name.
		location.replace(`${env.dotnetFrontendBaseUrl}${pathname}?${urlSearchParams}`);
	}, []);

	return <AppLoadingIndicator loadingReason="Loading…" />;
};

export default ExternalPage;
