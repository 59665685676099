import { initializeIcons } from "@fluentui/react";
import React, { useCallback, useRef } from "react";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { GlobalContextProviders, useAuthContext } from "../../contexts/global-context-providers";
import { hideInPrint } from "../../styles/02-tools/hide-in-print";
import { screenReaderOnly } from "../../styles/02-tools/screen-reader-only";
import { ResetStyles } from "../../styles/03-generic/reset";
import { tokens } from "../../styles/tokens";
import { useNavigationPerfMarker } from "../../utils/navigation-perf-marker";
import { useResizeObserver } from "../../utils/use-resize-observer";
import { useSkipToMain } from "../../utils/use-skip-to-main";
import { CommunicationMessage } from "../app-banner/communication-message";
import { NetworkStatusMessage } from "../app-banner/network-status-message";
import { AppHeader } from "../app-header/app-header";
import { AppPages } from "../app-pages/app-pages";
import { AppLoadingIndicator } from "./app-loading-indicator";
import { useInitializeAppInsights } from "./use-initialize-app-insights";

export const SITE_MAIN_ID = "maincontent";

export const messageStackHeightVariableName = "--message-stack-area-height";

const AppContent: React.FC = () => {
	const authContext = useAuthContext();

	const [searchParams] = useSearchParams();
	const isEmbed = searchParams.get("embed") === "true";

	useInitializeAppInsights();

	const skipToMain = useSkipToMain();

	const handleSkipToMain = useCallback((e: React.MouseEvent) => {
		// use javascript to handle event can prevent # string to show up. This keeps the URL clean
		e.preventDefault();
		skipToMain();
	}, []);

	// measure the height change
	const messageStackRef = useRef<HTMLDivElement>(null);
	useResizeObserver(
		messageStackRef,
		useCallback((size) => document.documentElement.style.setProperty(messageStackHeightVariableName, size.blockSize.toString() + "px"), [])
	);
	useNavigationPerfMarker();
	return (
		<>
			{!isEmbed && (
				<SkipLink href={`#${SITE_MAIN_ID}`} onClick={handleSkipToMain}>
					Skip to main content
				</SkipLink>
			)}
			{authContext.authState === "signed-in" && (
				<StyledPageRoot>
					{!isEmbed && (
						<>
							<AppHeader className="header classic-shim-header" />
							<MessageStack ref={messageStackRef}>
								<NetworkStatusMessage />
								<CommunicationMessage />
							</MessageStack>
						</>
					)}
					<ScrollArea tabIndex={-1} id={SITE_MAIN_ID} className="scroll-area">
						<AppPages />
					</ScrollArea>
				</StyledPageRoot>
			)}
			{authContext.authState === "signing-in" && <AppLoadingIndicator loadingReason="Authenticating…" />}
		</>
	);
};

export const AppRoot: React.FC = () => {
	return (
		<BrowserRouter>
			<ResetStyles />
			<GlobalContextProviders>
				<AppContent />
			</GlobalContextProviders>
		</BrowserRouter>
	);
};

const ScrollArea = styled.main`
	outline: none; /** programmatic focus zone should not receive outline */
	flex-grow: 1;
	padding-bottom: 4rem;

	@media not print {
		overflow-y: scroll; /** retain the scroll bar to avoid content jump */
	}
`;

const MessageStack = styled.div`
	${hideInPrint}
`;

const SkipLink = styled.a`
	&:not(:focus) {
		${screenReaderOnly}
	}

	&:focus {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 200;
		background-color: white;
		border: 1px solid black;
		border-radius: ${tokens.corners.radius2};
		padding: 8px 16px;
		color: black;
	}
`;

const StyledPageRoot = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

initializeIcons();
