import React, { lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate, PathRouteProps, Route, Routes } from "react-router-dom";
import { TrackerContextProvider } from "../../contexts/tracker-context";
import { entityMeta, entityTypes } from "../../utils/entity-meta";
import { AppLoadingIndicator } from "../app-root/app-loading-indicator";
import { ExternalPage } from "../external-page/external-page";

interface AppPageProps extends PathRouteProps {
	path: string;
	trackerScope: string;
	routeComponent: LazyExoticComponent<any> | React.FC<any>;
}

export const appPages: AppPageProps[] = [
	{
		trackerScope: "graphql-playground",
		path: "/my/graphql-playground/*",
		routeComponent: lazy(() => import("../graphql-playground-page/graphql-playground-page")),
	},
	{ trackerScope: "user-preferences", path: "/my/preferences/*", routeComponent: lazy(() => import("../user-preferences-page/user-preferences-page")) },
	{ trackerScope: "home", path: "/my/*", routeComponent: lazy(() => import("../home-page/home-page")) },
	{
		trackerScope: "create",
		path: "/create/*",
		routeComponent: lazy(() => import("../create-page/create-page")),
	},
	{
		trackerScope: "helpdesk",
		path: "/helpdesk/*",
		routeComponent: lazy(() => import("../customer-service-page/customer-service-page")),
	},
	{
		trackerScope: "feedback",
		path: "/feedback/*",
		routeComponent: lazy(() => import("../customer-service-page/customer-service-page")),
	},
	{
		trackerScope: "search",
		path: "/search",
		routeComponent: lazy(() => import("../search-page/search-page")),
	},
	{
		trackerScope: "hub",
		path: `/${entityMeta.get(entityTypes.topic)?.slugName}/*`,
		routeComponent: lazy(() => import("../hub-page/hub-page")),
	},
	{
		trackerScope: "hub",
		path: `/${entityMeta.get(entityTypes.product)?.slugName}/*`,
		routeComponent: lazy(() => import("../hub-page/hub-page")),
	},
	{
		trackerScope: "hub",
		path: `/${entityMeta.get(entityTypes.user)?.slugName}/*`,
		routeComponent: lazy(() => import("../hub-page/hub-page")),
	},
	{
		trackerScope: "hub",
		path: `/${entityMeta.get(entityTypes.researchGroup)?.slugName}/*`,
		routeComponent: lazy(() => import("../hub-page/hub-page")),
	},
	{
		trackerScope: "report",
		path: "/:entityTypeSlug/create/*",
		routeComponent: lazy(() => import("../report-page/report-editor")),
	},
	{
		path: "/:entityTypeSlug/preview/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
	{
		path: "/:entityTypeSlug/edit/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-editor")),
		trackerScope: "report",
	},
	{
		path: "/study/present/:entityIdString/*",
		routeComponent: lazy(() => import("../presentation-page/presentation-page-loader")),
		trackerScope: "presentation",
	},
	{
		path: "/collection/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
	{
		path: "/study/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
	{
		path: "/note/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
	{
		path: "/recommendation/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
	{
		path: "/insight/:entityIdString/*",
		routeComponent: lazy(() => import("../report-page/report-viewer")),
		trackerScope: "report",
	},
];

export const AppPages: React.FC = () => {
	return (
		<Suspense fallback={<AppLoadingIndicator loadingReason="Loading app…" />}>
			<Routes>
				<Route path="/" element={<Navigate to="/my" replace />} />
				{appPages.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						element={
							<TrackerContextProvider scope={route.trackerScope}>
								<route.routeComponent />
							</TrackerContextProvider>
						}
					/>
				))}
				<Route
					path="*"
					element={
						<TrackerContextProvider scope="redirect">
							<ExternalPage />
						</TrackerContextProvider>
					}
				></Route>
			</Routes>
		</Suspense>
	);
};
