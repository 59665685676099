import type { IEventHub } from "./event-hub-types";

/**
 * Light wrapper for DOM EventTarget
 * 1. No customization (no bubble or cancel, etc.)
 * 2. Easier access to `details` object
 * 3. addEventListener returns a callback for removing it
 */
export class EventHub<TEventDict extends Record<string, any>> implements IEventHub<TEventDict> {
	private eventTarget = new EventTarget();

	/**
	 * @returns a function that removes the listener
	 */
	on<K extends Extract<keyof TEventDict, string>>(eventName: K, listener: (data: TEventDict[K]) => any, options?: boolean | AddEventListenerOptions) {
		const wrappedListener = ((e: CustomEvent<any>) => listener(e.detail)) as EventListener;

		this.eventTarget.addEventListener(eventName, wrappedListener, options);
		return () => this.eventTarget.removeEventListener(eventName, wrappedListener, options);
	}

	emit<K extends Extract<keyof TEventDict, string>>(eventName: K, ...args: TEventDict[K] extends undefined ? [] : [data: TEventDict[K]]) {
		this.eventTarget.dispatchEvent(new CustomEvent(eventName, { detail: args[0] }));
	}
}
