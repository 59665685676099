import { HitsRole } from "@hits/rest-api-types";
import { joinByBitwiseOr, matchAnyBit } from "../../utils/bitwise";
import { hitsRoles } from "../../utils/role-meta";

/**
 * Client side feature availablility based on user roles
 * Having this centralized property bag allows us to track what's on and off across the entire app
 * Alphabetical sort please
 */
export interface RoleBasedFeatureFlags {
	/** Access to debugging and dev tool */
	isAdminDevToolEnabled: boolean;
	/** Does user meet the minimum requirement to view anything from the app */
	isAppVisible: boolean;
	/** Can user change a report's method, regardless of its creator's permissions */
	isChangeResearchMethodAllowed: boolean;
	/** Can user create new study or collection */
	isCreateResearchAllowed: boolean;
	/** Can user create at least one type of content  */
	isCreateAllowed: boolean;
	/** Can user clone a report */
	isCloneAllowed: boolean;
	/** Should user see discover tab before personal content tab */
	isDiscoverPrioritized: boolean;
	/** Access to admin-relaled hub customization ux */
	isHubAdminEditorEnabled: boolean;
	/** Can user visit their own hub */
	isUserHubAccessible: boolean;
}

export function getRoleBasedFeatureFlags(roles: HitsRole[]): RoleBasedFeatureFlags {
	const userRoleBits = joinByBitwiseOr(...roles);
	const matchUserRoleBits = (mask: number) => matchAnyBit(userRoleBits, mask);

	return {
		isAdminDevToolEnabled: matchUserRoleBits(hitsRoles.admin),
		isAppVisible: matchUserRoleBits(
			hitsRoles.guestUser | hitsRoles.user | hitsRoles.pilotUser | hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin
		),
		isChangeResearchMethodAllowed: matchUserRoleBits(hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin),
		isCloneAllowed: matchUserRoleBits(hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin),
		isCreateAllowed: matchUserRoleBits(
			hitsRoles.user | hitsRoles.pilotUser | hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin
		),
		isCreateResearchAllowed: matchUserRoleBits(hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin),
		isDiscoverPrioritized: matchUserRoleBits(hitsRoles.none | hitsRoles.guestUser | hitsRoles.user | hitsRoles.pilotUser),
		isHubAdminEditorEnabled: matchUserRoleBits(hitsRoles.admin),
		isUserHubAccessible: matchUserRoleBits(
			hitsRoles.user | hitsRoles.pilotUser | hitsRoles.researcher | hitsRoles.leadResearcher | hitsRoles.editor | hitsRoles.admin
		),
	};
}
