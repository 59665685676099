import { Spinner } from "@fluentui/react";
import React from "react";
import styled from "styled-components";
import { LiveAnnouncer } from "../live-announcer/live-announcer";

const StyledSpinner = styled.div`
	margin: var(--item-vertical-padding) var(--item-side-padding);
	align-self: start;
	justify-content: start;
`;

export interface InlineSpinnerProps {
	/** @default "Loading…" */
	label?: string;
	className?: string;
}

// Replace Fluent spinner aria-live with custom LiveAnnouncer because some screenreaders ignore
// the initial label in the spinner

export const InlineSpinner: React.FC<InlineSpinnerProps> = (props) => (
	<>
		<StyledSpinner as={Spinner} className={props.className} aria-live={"off"} label={props.label ?? "Loading…"} labelPosition="right" />
		<LiveAnnouncer>{props.label ?? "Loading…"}</LiveAnnouncer>
	</>
);
