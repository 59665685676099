import { gql } from "@apollo/client";
import { FavoriteEntityType } from "@hits/rest-api-types";

export const FAVORITES = gql`
	query Favorites {
		favorites {
			createdOn
			entityId
			entityType
			relatedEntity {
				title
				isPublished
				family {
					researchers {
						alias
						directoryObjectId
						displayName
					}
				}
			}
		}
	}
`;

export interface Favorites {
	favorites: DisplayFavorite[];
}

export interface DisplayFavorite {
	createdOn: string;
	entityId: number;
	entityType: FavoriteEntityType;
	relatedEntity: FavoriteRelatedEntity | null;
}

export interface FavoriteRelatedEntity {
	title: string;
	isPublished: boolean;
	family: {
		researchers: {
			alias: string;
			directoryObjectId: string;
			displayName: string;
		}[];
	} | null;
}
