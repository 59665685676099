import { MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIsOffline } from "../../utils/use-is-offline";
import { RestylableLink } from "../shared/link/link";

export const NetworkStatusMessage: React.FC = () => {
	const [isVisible, setIsVisible] = useState(true);
	const isOffline = useIsOffline();
	const [isOfflineOnce, setIsOfflineOnce] = useState(isOffline);

	useEffect(() => {
		if (isOfflineOnce) return;

		setIsOfflineOnce(isOffline);
	}, [isOffline, isOfflineOnce]);

	return isOfflineOnce && isVisible ? (
		<MessageBar
			aria-live="assertive"
			dismissButtonAriaLabel="Close"
			messageBarType={isOffline ? MessageBarType.severeWarning : MessageBarType.warning}
			onDismiss={() => setIsVisible(false)}
		>
			{isOffline && "Network connection lost. Please verify your network connection."}
			{!isOffline && (
				<>
					Network connection disrupted. Please <EmbeddedLink href={location.href}>reload the page</EmbeddedLink> to resolve any issues.
				</>
			)}
		</MessageBar>
	) : null;
};

const EmbeddedLink = styled(RestylableLink)`
	padding: 0 !important; // fluent UI forces unnecessary padding on links
`;
