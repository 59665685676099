import { useCallback } from "react";
import { SITE_MAIN_ID } from "../components/app-root/app-root";

/**
 * Set focus on the main element of the page
 */
export function useSkipToMain() {
	return useCallback(() => {
		(document.querySelector(`#${SITE_MAIN_ID}`) as HTMLElement)?.focus();
	}, []);
}
