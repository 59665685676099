import { useMutation, useQuery } from "@apollo/client";
import { EntityType, FavoriteConfig, FavoriteEntityType } from "@hits/rest-api-types";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { FAVORITE_REPORT, FavoriteReportInput, FavoriteReportOutput } from "../apis/mutations/favorite-report";
import { UNFAVORITE_REPORT, UnfavoriteReportInput, UnfavoriteReportOutput } from "../apis/mutations/unfavorite-report";
import { FAVORITES, FavoriteRelatedEntity, Favorites } from "../apis/queries/favorites";
import { getReportUrl } from "../utils/get-report-url";
import { useAuthContext } from "./auth-context";

export interface EnrichedFavorite {
	createdOn: string;
	entityId: number;
	entityType: FavoriteEntityType;
	navigateTo: string;
	relatedEntity?: FavoriteRelatedEntity | null;
}

export interface FavoritesContextType {
	favorites: EnrichedFavorite[] | null | undefined;
	isFavorite: (entityType?: FavoriteEntityType, entityId?: number) => boolean | undefined;
	addFavorite: (entityType: FavoriteEntityType, entityId: number) => void;
	removeFavorite: (entityType: FavoriteEntityType, entityId: number) => void;
}

const FavoritesContext = createContext<FavoritesContextType>({
	favorites: undefined,
	isFavorite: () => undefined,
	addFavorite: () => {},
	removeFavorite: () => {},
});

export const FavoritesContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const [favorites, setFavorites] = useState<EnrichedFavorite[]>();
	const { account } = useAuthContext();

	const { data: favoritesData } = useQuery<Favorites>(FAVORITES, { skip: !account });
	const [favoriteReport] = useMutation<FavoriteReportOutput, FavoriteReportInput>(FAVORITE_REPORT);
	const [unfavoriteReport] = useMutation<UnfavoriteReportOutput, UnfavoriteReportInput>(UNFAVORITE_REPORT);

	const isFavorite = useCallback(
		(entityType?: EntityType, entityId?: number) => {
			if (!entityType || !entityId) return;
			if (!favorites) return;

			return favorites.some((favorite) => favorite.entityType === entityType && favorite.entityId === entityId);
		},
		[favorites]
	);

	/**
	 * assumption: this method will only be used after favorites array is initialized
	 */
	const addFavorite = useCallback(async (entityType: FavoriteEntityType, entityId: number) => {
		// optimistic ux: add stub favorite immediate and revert only when server call fails
		// TODO replace it with Apollo GraphQL built-in optimistic UX
		setFavorites((prevFavorites) => {
			const newFavorite: EnrichedFavorite = {
				entityType,
				entityId,
				createdOn: new Date().toISOString(),
				navigateTo: getReportUrl(entityType, entityId),
			};

			return [newFavorite, ...prevFavorites!];
		});

		const config: FavoriteConfig = {
			entityType,
			entityId,
		};

		try {
			const response = await favoriteReport({ variables: { args: config } });
			const newFavorite = response.data?.favoriteReport;

			if (response.errors?.length || !newFavorite) throw response.errors?.[0] || new Error();

			setFavorites((prevFavorites) => {
				const clonedFavorites = [...prevFavorites!];
				const addedFavorite = clonedFavorites.find((favorite) => favorite.entityId === entityId && favorite.entityType === entityType);
				if (addedFavorite) {
					addedFavorite.createdOn = newFavorite.createdOn;
					addedFavorite.relatedEntity = newFavorite.relatedEntity;
				}

				return clonedFavorites;
			});
		} catch (error: any) {
			console.error("Favorite failed, roll back", error);
			setFavorites((prevFavorites) => {
				const clonedFavorites = [...prevFavorites!];
				const validFavorites = clonedFavorites.filter((favorite) => favorite.entityId !== entityId || favorite.entityType !== entityType);
				return validFavorites;
			});
		}
	}, []);

	/**
	 * assumption: this method will only be used after favorites array is initialized
	 */
	const removeFavorite = useCallback(async (entityType: FavoriteEntityType, entityId: number) => {
		// optimistic ux: remove favorite immediate and revert only when server call fails
		let backupFavorites: EnrichedFavorite[];

		setFavorites((prevFavorites) => {
			backupFavorites = [...prevFavorites!];
			const remainingFavorites = backupFavorites.filter((favorite) => favorite.entityId !== entityId || favorite.entityType !== entityType);
			return remainingFavorites;
		});

		const config: FavoriteConfig = {
			entityType,
			entityId,
		};

		try {
			const response = await unfavoriteReport({ variables: { args: config } });
			const removedFavoriteEntityId = response?.data?.unfavoriteReport.entityId;
			if (response.errors?.length || !removedFavoriteEntityId) throw response.errors?.[0] || new Error();
		} catch (error: any) {
			console.log("Unfavorite failed, roll back", error);
			// Other changes could have been made since backend was created. But we are already in error case, it's ok to revert more aggressively.
			setFavorites(() => backupFavorites);
		}
	}, []);

	// fetch basic favorites
	useEffect(() => {
		if (!favoritesData) return;

		const displayFavorites: EnrichedFavorite[] = favoritesData.favorites.map((favorite) => ({
			...favorite,
			navigateTo: getReportUrl(favorite.entityType, favorite.entityId),
		}));

		setFavorites(displayFavorites);
	}, [favoritesData]);

	const contextObject = {
		favorites,
		isFavorite,
		addFavorite,
		removeFavorite,
	};

	return <FavoritesContext.Provider value={contextObject}>{props.children}</FavoritesContext.Provider>;
};

export function useFavoritesContext() {
	return useContext(FavoritesContext);
}
