import { useCallback } from "react";
import {
	OpenSearchBoxContent,
	SearchBoxExitAction,
	SearchBoxSessionSummary,
	useTrackEndSearchBoxSession,
	useTrackOpenSearchBoxContent,
} from "../../../utils/use-tracker";

// since we have only one search box per site, we can safely use module scope variables outside of hook
let uploadedExitAction: SearchBoxExitAction | null = null;
let isDropdownOpen = false;
let currentQuery = "";
let sessionStartTime: number | undefined = undefined;

export function useSearchBoxAnalytics() {
	const trackEndSearchBoxSession = useTrackEndSearchBoxSession();
	const trackOpenSearchBoxContent = useTrackOpenSearchBoxContent();

	const trackOpenDropdown = useCallback(() => {
		uploadedExitAction = null;
		isDropdownOpen = true;
		sessionStartTime = performance.now();
		console.log(`[analytics] quick find start`);
	}, []);

	const trackCloseDropdown = useCallback(() => {
		// only track if the dropdown is currently open
		if (!isDropdownOpen) return;

		console.log(`[analytics] quick find abandon`);
		uploadResult("abandon");
		isDropdownOpen = false;
	}, []);

	const trackQueryUpdate = useCallback((q: string) => {
		currentQuery = q;
	}, []);

	const trackOpenFindResult = useCallback((args: OpenSearchBoxContent) => {
		trackOpenSearchBoxContent(args);

		console.log(`[analytics] quick find open`, args);
		uploadResult("open");
	}, []);

	const trackCommit = useCallback(() => {
		console.log(`[analytics] quick find commit`);
		uploadResult("commit");
	}, []);

	const uploadResult = useCallback((exitAction: SearchBoxExitAction) => {
		// prevent duplicated tracking
		if (uploadedExitAction !== null) {
			return;
		}

		const sessionSummary: SearchBoxSessionSummary = {
			exitQuery: currentQuery,
			exitAction,
			duration: (performance.now() - sessionStartTime!) / 1000,
		};

		console.log(`[analytics] quick find summary`, sessionSummary);

		trackEndSearchBoxSession(sessionSummary);
		uploadedExitAction = exitAction;
		isDropdownOpen = false;
	}, []);

	return {
		trackOpenFindResult,
		trackOpenDropdown,
		trackCloseDropdown,
		trackQueryUpdate,
		trackCommit,
	};
}
