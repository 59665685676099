import { gql } from "@apollo/client";
import { FavoriteEntityType } from "@hits/rest-api-types";

export const UNFAVORITE_REPORT = gql`
	mutation UnfavoriteReport($args: UnfavoriteReportArgs!) {
		unfavoriteReport(args: $args) {
			entityId
		}
	}
`;

export interface UnfavoriteReportInput {
	args: {
		entityId: number;
		entityType: FavoriteEntityType;
	};
}

export interface UnfavoriteReportOutput {
	unfavoriteReport: {
		entityId: number | null;
	};
}
