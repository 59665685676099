import { useMutation } from "@apollo/client";
import { UserVisitEntityType } from "@hits/rest-api-types";
import React, { createContext, useContext, useEffect } from "react";
import { ADD_PAGE_VIEW, AddPageViewInput, AddPageViewOutput } from "../../apis/mutations/add-page-view";
import { appInsights } from "../../utils/app-insights";
import { referrer } from "../../utils/referrer";
import { useSessionPromoCode } from "../../utils/use-session-promo-code";
import { usePageViewsCacheEviction } from "./use-page-view-cache-eviction";

export interface TrackPageViewProps {
	pageName: string;
}

export interface TrackPageViewWithGraphProps {
	pageName: string;
	entityId: number;
	entityType: UserVisitEntityType;
}

export interface PageViewContextType {
	trackPageView: (props: TrackPageViewProps) => void;
	trackPageViewWithGraph: (props: TrackPageViewWithGraphProps) => void;
}

const PageViewContext = createContext<PageViewContextType>({
	trackPageView: () => {},
	trackPageViewWithGraph: () => {},
});

export const PageViewContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const promoCode = useSessionPromoCode();
	const [addPageView] = useMutation<AddPageViewOutput, AddPageViewInput>(ADD_PAGE_VIEW);

	// Note: addPageViewToGraph should not evict the pageView cache because modern frontent page views are Hub pages only, which are not considered as part of in history
	// In the future, we should evict cache if either of the following becomes true:
	// 1. Modern frontend includes Hub page visits as part of the history
	// 2. Modern frontend becomes the host for report pages
	usePageViewsCacheEviction();

	const addPageViewToAppInsight = (props: TrackPageViewProps, referrerUrl: string) => {
		console.log(`[page-view] Visit page ${props.pageName}${promoCode ? `:${promoCode}` : ""} from ${referrerUrl}`);
		appInsights.trackPageView({
			name: props.pageName,
			refUri: referrerUrl,
			properties: {
				urlReferrer: referrerUrl, // add this in addition to refUri for backward compatiblity
			},
		});
	};

	const addPageViewToGraph = (props: TrackPageViewWithGraphProps, referrerUrl: string) => {
		const { entityId, entityType } = props;

		console.log(`[page-view] Visit entity ${props.entityType}:${props.entityId} from ${referrerUrl}`);
		addPageView({
			variables: {
				args: {
					entityId,
					entityType,
					url: location.href,
					referrerUrl,
					sessionId: appInsights.context.getSessionId(),
				},
			},
		});
	};

	/**
	 * This tracker is mutually exclusive with `trackPageViewWithGraph`
	 * The goal is to migrate all page view tracking into the Graph
	 */
	const trackPageView = (props: TrackPageViewProps) => {
		const referrerUrl = referrer.get();
		addPageViewToAppInsight(props, referrerUrl);
		referrer.set(window.location.href);
	};

	/**
	 * This tracker is mutually exclusive with `trackPageView`
	 * The goal is to migrate all page view tracking into the Graph
	 */
	const trackPageViewWithGraph = (props: TrackPageViewWithGraphProps) => {
		const referrerUrl = referrer.get();
		addPageViewToAppInsight(props, referrerUrl);
		addPageViewToGraph(props, referrerUrl);
		referrer.set(window.location.href);
	};

	const contextObject: PageViewContextType = {
		trackPageView,
		trackPageViewWithGraph,
	};

	return <PageViewContext.Provider value={contextObject}>{props.children}</PageViewContext.Provider>;
};

export function useTrackPageView(props: Partial<TrackPageViewProps>) {
	const pageViewContext = useContext(PageViewContext);
	const { pageName } = props;
	useEffect(() => {
		if (!pageName) return;
		pageViewContext.trackPageView({ pageName });
	}, [pageName]);
}

export function useTrackPageViewWithGraph(props: Partial<TrackPageViewWithGraphProps>) {
	const pageViewContext = useContext(PageViewContext);
	const { pageName, entityId, entityType } = props;
	useEffect(() => {
		if (!pageName || !entityId || !entityType) return;
		pageViewContext.trackPageViewWithGraph({ pageName, entityId, entityType });
	}, [pageName, entityId, entityType]);
}
