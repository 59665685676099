import { ApolloProvider } from "@apollo/client";
import React from "react";
import { apolloService } from "../utils/apollo-service";
import { AuthContextProvider } from "./auth-context";
import { CustomerServiceDialogContextProvider } from "./customer-service-dialog-context";
import { DirectoryContextProvider } from "./directory-context";
import { FavoritesContextProvider } from "./favorites-context";
import { PageViewContextProvider } from "./page-view-context/page-view-context";
import { SiteSearchContextProvider } from "./site-search-context";
import { ThemeContextProvider } from "./theme-context";
import { UserPhotosContextProvider } from "./user-photos-context";
import { UserProfileContextProvider } from "./user-profile-context/user-profile-context";

export * from "./auth-context";

export const GlobalContextProviders: React.FC<React.PropsWithChildren> = (props) => {
	return (
		<ApolloProvider client={apolloService.client}>
			<PageViewContextProvider>
				<ThemeContextProvider>
					<AuthContextProvider>
						<DirectoryContextProvider>
							<UserPhotosContextProvider>
								<UserProfileContextProvider>
									<FavoritesContextProvider>
										<SiteSearchContextProvider>
											<CustomerServiceDialogContextProvider>{props.children}</CustomerServiceDialogContextProvider>
										</SiteSearchContextProvider>
									</FavoritesContextProvider>
								</UserProfileContextProvider>
							</UserPhotosContextProvider>
						</DirectoryContextProvider>
					</AuthContextProvider>
				</ThemeContextProvider>
			</PageViewContextProvider>
		</ApolloProvider>
	);
};
