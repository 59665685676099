import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { env } from "./env";

export const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: env.aiInstrumentationKey,
		enableCorsCorrelation: true,
		enableUnhandledPromiseRejectionTracking: true,
	},
});
appInsights.loadAppInsights();

/**
 * A custom could role is needed because modern frontend currently shares application insight app id with MVC frontend.
 * When modern frontend becomes fully standalone, this can be removed.
 * Ref: https://docs.microsoft.com/en-us/azure/azure-monitor/app/app-map?tabs=javascript#set-or-override-cloud-role-name
 */
appInsights.addTelemetryInitializer((envelope) => {
	if (!envelope.tags) {
		envelope.tags = [];
	}

	envelope.tags["ai.cloud.role"] = "modern-frontend";
});
