import { useQuery } from "@apollo/client";
import { FontIcon } from "@fluentui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { SearchboxHistoryItemsInput, SearchboxHistoryItemsOutput, SEARCHBOX_HISTORY_ITEMS } from "../../../apis/queries/searchbox-history-items";
import { useUserProfileContext } from "../../../contexts/user-profile-context/user-profile-context";
import { entityMeta } from "../../../utils/entity-meta";
import { formatTitle } from "../../../utils/format-title";
import { getReportUrl } from "../../../utils/get-report-url";
import { InlineSpinner } from "../../shared/inline-spinner/inline-spinner";
import { LiveAnnouncer } from "../../shared/live-announcer/live-announcer";
import { ExceptionMessage } from "./_exception-message";
import { GroupTitle } from "./_group-title";
import { SuggestionItem, SuggestionItemIcon, SuggestionItemText } from "./_suggestion-item";

export interface DropdownHistoryProps {
	className?: string;
	onOpenHistoryItem: (event: React.MouseEvent) => void;
}

interface HistoryItem {
	/** a description with both content type and title, used for hover tooltip and screenreader */
	tooltip: string;
	title: string;
	link: string;
	iconName: string;
	iconTooltip: string;
}

export const DROPDOWN_PAGE_VIEW_LIMIT = 7;

export const DropdownHistory: React.FC<DropdownHistoryProps> = (props) => {
	const userProfile = useUserProfileContext();
	const { loading, error, data } = useQuery<SearchboxHistoryItemsOutput, SearchboxHistoryItemsInput>(SEARCHBOX_HISTORY_ITEMS, {
		variables: {
			args: {
				userId: userProfile.myProfile?.user.id ?? 0,
				limit: DROPDOWN_PAGE_VIEW_LIMIT,
			},
		},
		skip: !userProfile.myProfile?.user.id,
	});

	const displayItems: HistoryItem[] | [] =
		data?.pageViews
			.filter((item) => item.relatedEntity)
			.map((item) => ({
				tooltip: `${entityMeta.get(item.entityType)!.displayName!}: ${item.relatedEntity!.title}`,
				title: formatTitle(item.relatedEntity!.title, { isDraft: !item.relatedEntity!.isPublished }),
				link: getReportUrl(item.entityType, item.entityId),
				iconName: entityMeta.get(item.entityType)!.iconName!,
				iconTooltip: entityMeta.get(item.entityType)!.displayName!,
			})) || [];

	return (
		<RecentSection className={props.className}>
			<GroupTitle>Recent</GroupTitle>
			{loading && <InlineSpinner />}
			{!loading &&
				displayItems!.length > 0 &&
				displayItems!.map((item) => (
					<SuggestionItem
						as={RouterLink}
						key={item.link}
						to={item.link}
						data-is-focusable={true}
						title={item.tooltip}
						aria-label={item.tooltip}
						onClick={props.onOpenHistoryItem}
						role="option"
					>
						<SuggestionItemIcon as={FontIcon} iconName={item.iconName} aria-label={item.iconTooltip} title={item.iconTooltip} />
						<SuggestionItemText>{item.title}</SuggestionItemText>
					</SuggestionItem>
				))}
			<ExceptionMessage role="status">
				{!loading && displayItems!.length === 0 && "No recent items are found."}
				{error && "Error loading recent items."}
			</ExceptionMessage>
			<LiveAnnouncer>{!loading && displayItems!.length > 0 && `Suggestions: ${displayItems.length} recent items`}</LiveAnnouncer>
		</RecentSection>
	);
};

const RecentSection = styled.section`
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;
`;
