import { EntityType } from "@hits/rest-api-types";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFiltersFromSearchObject, replaceFiltersFacetValues } from "./search-url-utils";

export const EMPTY_QUERY = "*";
const DEFAULT_SKIP = 0;
const DEFAULT_TOP = 10;

export type Sort = "most-relevant" | "most-recent";
const DEFAULT_SORT = "most-relevant";

export interface Filters {
	entityTypes: EntityType[];
	topicIds: number[];
	productIds: number[];
	methodIds: number[];
	groupIds: number[];
	researcherIds: number[];
	publishDateRange: string[]; // [from, to]
	isPublished?: boolean;
}

export interface UseManagedUrlOutput {
	fullQuery: string;
	q: string;
	sort: Sort;
	filters: Filters;
	top: number;
	skip: number;
	currentPage: number; // After skipping, which page (0 based) are the results on
	spellCheck: boolean;
	setQ: (q: string) => void;
	setSort: (sort: Sort) => void;
	setSkip: (skip: number) => void;
	setPage: (page: number) => void;
	setPrevPage: () => void;
	setNextPage: () => void;
	setFiltersFacet: SetFiltersFacet;
	setFiltersFacetValueItem: SetFiltersFacetValueItem;
	setSpellCheck: (isSpellCheck: boolean) => void;
}

export type SetFiltersFacet = <T extends keyof Filters>(facet: T, values: Filters[T]) => void;
export type SetFiltersFacetValueItem = <T extends keyof Filters>(
	facet: T,
	valueItem: Filters[T] extends any[] ? Filters[T][0] : Filters[T],
	include: boolean
) => void;

export function useManagedUrl(): UseManagedUrlOutput {
	const { search, pathname } = useLocation();
	const navigate = useNavigate();

	const searchObject = useMemo(() => {
		const result = new URLSearchParams(search);

		// ensure required values
		if (!result.get("q")) result.set("q", EMPTY_QUERY);
		if (!result.get("top")) result.set("top", DEFAULT_TOP.toString());
		if (!result.get("sort")) result.set("sort", DEFAULT_SORT);

		return result;
	}, [search]);

	/*
	 * ===========
	 * GETTERS
	 * ===========
	 */

	const q = useMemo(() => searchObject.get("q")!, [searchObject]);
	const top = useMemo(() => parseInt(searchObject.get("top")!), [searchObject]);
	const sort = useMemo(() => searchObject.get("sort") as Sort, [searchObject]);

	const filters = useMemo(() => {
		return getFiltersFromSearchObject(searchObject);
	}, [searchObject]);

	const skip = useMemo(() => {
		const skipString = searchObject.get("skip");
		return skipString ? parseInt(skipString) : DEFAULT_SKIP;
	}, [searchObject]);

	const currentPage = useMemo(() => {
		if (skip % top > 0) {
			console.error(`[search] skip(=${skip}) is not multiples of top(=${top})`);
		}

		return Math.floor(skip / top);
	}, [top, skip]);

	const spellCheck = useMemo(() => {
		// by default, we should include spell correction results.
		const spellCheckString = searchObject.get("spellCheck");
		return spellCheckString !== "false"; // watch out, this is a string
	}, [searchObject]);

	/*
	 * ===========
	 * SETTERS
	 * ===========
	 */
	const setSpellCheck = useCallback(
		(isSpellCheck: boolean) => {
			if (isSpellCheck) {
				searchObject.delete("spellCheck");
			} else {
				searchObject.set("spellCheck", "false");
			}

			const newUrl = `${pathname}?${searchObject}`;
			navigate(newUrl);
		},
		[pathname, searchObject]
	);

	const setQ = useCallback(
		(q: string) => {
			// updating query should bust all pagination and filter
			_resetPagination();
			_resetFilters();

			searchObject.set("q", q);
			const newUrl = `${pathname}?${searchObject}`;
			navigate(newUrl);
		},
		[pathname, searchObject]
	);

	const setSort = useCallback(
		(sort: Sort) => {
			searchObject.set("sort", sort);

			const newUrl = `${pathname}?${searchObject}`;
			navigate(newUrl);
		},
		[pathname, searchObject]
	);

	const setSkip = useCallback(
		(skip: number) => {
			if (skip > 0) {
				searchObject.set("skip", skip.toString());
			} else {
				searchObject.delete("skip");
			}

			const newUrl = `${pathname}?${searchObject}`;
			navigate(newUrl);
		},
		[pathname, searchObject]
	);

	const setFiltersFacet = useCallback(
		<T extends keyof Filters>(facet: T, values: Filters[T]) => {
			_resetPagination();

			const newSearchObj = replaceFiltersFacetValues(searchObject, facet, values);

			const newUrl = `${pathname}?${newSearchObj}`;
			navigate(newUrl);
		},
		[pathname, searchObject]
	);

	const setFiltersFacetValueItem = useCallback(
		<T extends keyof Filters>(facet: T, valueItem: Filters[T] extends any[] ? Filters[T][0] : Filters[T], include: boolean) => {
			let newValues: Filters[T];

			if (Array.isArray(filters[facet])) {
				newValues = [...(filters[facet] as any[])] as Filters[T];

				if (include) {
					(newValues as any[]).push(valueItem);
				} else {
					newValues = (newValues as any[]).filter((value) => value !== valueItem) as Filters[T];
				}
			} else {
				newValues = valueItem;
			}

			setFiltersFacet(facet, newValues);
		},
		[filters, setFiltersFacet]
	);

	const setPage = useCallback((page: number) => setSkip(page * top), [setSkip, top]);
	const setPrevPage = useCallback(() => setSkip(Math.max(skip - top, 0)), [skip, setSkip, top]);
	const setNextPage = useCallback(() => setSkip(Math.max(skip + top)), [skip, setSkip, top]);

	const _resetPagination = useCallback(() => {
		searchObject.delete("skip");

		const newUrl = `${pathname}?${searchObject}`;
		navigate(newUrl, { replace: true });
	}, [pathname, searchObject]);

	const _resetFilters = useCallback(() => {
		searchObject.delete("filter");

		const newUrl = `${pathname}?${searchObject}`;
		navigate(newUrl, { replace: true });
	}, [pathname, searchObject]);

	return {
		currentPage,
		filters,
		fullQuery: searchObject.toString(),
		q,
		skip,
		sort,
		spellCheck,
		top,
		setFiltersFacet,
		setFiltersFacetValueItem,
		setNextPage,
		setPage,
		setPrevPage,
		setQ,
		setSkip,
		setSort,
		setSpellCheck,
	};
}
