import { gql } from "@apollo/client";

export const MY_CONTRIBUTION_STATS = gql`
	query MyContributionStats {
		myContent {
			publishedNotesCount
			publishedStudiesCount
			publishedCollectionsCount
		}
	}
`;

export interface MyContributionStats {
	myContent: {
		publishedNotesCount: number;
		publishedStudiesCount: number;
		publishedCollectionsCount: number;
	};
}
