import { EntityType } from "@hits/rest-api-types";
import { entityMeta } from "./entity-meta";

export function getReportUrl(entityType: EntityType, entityId: number): string {
	const entityTypeSlug = entityMeta.get(entityType)?.slugName;

	if (!entityTypeSlug) {
		console.error(`No report url is configured for entityType=${entityType}`);
		return "/";
	}

	return `/${entityTypeSlug}/${entityId}`;
}
