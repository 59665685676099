import { useQuery } from "@apollo/client";
import { Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";
import styled from "styled-components";
import { META, Meta } from "../../../apis/queries/meta";

export interface AboutDialogProps {
	hidden: boolean;
	onClose: () => void;
}

export const AboutDialog: React.FC<AboutDialogProps> = (props) => {
	const { data: metaData, loading: isServerVersionLoading } = useQuery<Meta>(META);

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={props.onClose}
			dialogContentProps={{
				title: "About",
				type: DialogType.normal,
			}}
		>
			<dl>
				<StyledDescriptionTerm>Branch</StyledDescriptionTerm>
				<StyledDescriptionDetails> {process.env.SOURCE_BRANCH_NAME}</StyledDescriptionDetails>
				<StyledDescriptionTerm>Build</StyledDescriptionTerm>
				<StyledDescriptionDetails>{process.env.BUILD_ID}</StyledDescriptionDetails>
				<StyledDescriptionTerm>Web package</StyledDescriptionTerm>
				<StyledDescriptionDetails>{process.env.WEB_PACKAGE_VERSION}</StyledDescriptionDetails>
				<StyledDescriptionTerm>API server package</StyledDescriptionTerm>
				<StyledDescriptionDetails>{isServerVersionLoading ? "Loading…" : metaData?.meta.packageVersion ?? "Unknown"}</StyledDescriptionDetails>
				<StyledDescriptionTerm>API server node</StyledDescriptionTerm>
				<StyledDescriptionDetails>{isServerVersionLoading ? "Loading…" : metaData?.meta.nodeVersion ?? "Unknown"}</StyledDescriptionDetails>
				<StyledDescriptionTerm>API server functions extension</StyledDescriptionTerm>
				<StyledDescriptionDetails>
					{isServerVersionLoading ? "Loading…" : metaData?.meta.functionsExtensionVersionRequested ?? "Unknown"}
				</StyledDescriptionDetails>
				<StyledDescriptionTerm>Last commit</StyledDescriptionTerm>
				<StyledDescriptionDetails>{process.env.COMMIT_HASH}</StyledDescriptionDetails>
			</dl>
			<DialogFooter>
				<PrimaryButton onClick={props.onClose} text="Close" />
			</DialogFooter>
		</Dialog>
	);
};

const StyledDescriptionTerm = styled.dt`
	font-weight: 600;
`;

const StyledDescriptionDetails = styled.dd`
	margin: 0 0 8px 0;
	font-family: monospace;
`;
