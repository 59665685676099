import { useEffect } from "react";

export interface UseLocalStorageWatcherProps {
	key: string;
	onChange: () => void;
}

/**
 * Call the onChange function when an item with the given key changes its value in local storage
 */
export function useLocalStorageWatcher(props: UseLocalStorageWatcherProps) {
	useEffect(() => {
		const listener = (ev: StorageEvent) => {
			if (ev.key === props.key) {
				props.onChange();
			}
		};

		window.addEventListener("storage", listener);

		return () => window.removeEventListener("storage", listener);
	}, [props.key, props.onChange]);
}
