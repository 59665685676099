import React, { createContext, useCallback, useContext, useRef, useState } from "react";

export interface SiteSearchContextType {
	searchBoxRef: React.RefObject<HTMLInputElement>;
	isHeaderTakeover: boolean;
	takeoverHeader: () => void;
	releaseHeader: () => void;
}

// TODO this context can be refactored into the component, because no other component needs to access it
const SiteSearchContext = createContext<SiteSearchContextType>({
	searchBoxRef: { current: null },
	isHeaderTakeover: false,
	takeoverHeader: () => {},
	releaseHeader: () => {},
});

export const SiteSearchContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const [isHeaderTakeover, setIsHeaderTakeover] = useState(false);

	const takeoverHeader = useCallback(() => setIsHeaderTakeover(true), []);
	const releaseHeader = useCallback(() => setIsHeaderTakeover(false), []);

	const contextObject = {
		searchBoxRef,
		isHeaderTakeover,
		takeoverHeader,
		releaseHeader,
	};

	return <SiteSearchContext.Provider value={contextObject}>{props.children}</SiteSearchContext.Provider>;
};

export function useSiteSearchContext() {
	return useContext(SiteSearchContext);
}
