import { useCallback } from "react";
import { useUserProfileContext } from "../contexts/user-profile-context/user-profile-context";
import { env } from "../utils/env";
import { roleMeta } from "../utils/role-meta";

/**
 * The types for the "Sas" manifestType
 * Ref: https://petrolapi.azurewebsites.net/schemas/v1/manifest.jsonSchema.json
 */
export type OCVIssueType = "Smile" | "Frown" | "Idea" | "Bug";

export interface CreateIssueClaim {
	commit?: string;
	email: string;
	messageBody: string;
	screenshot?: File | null;
	type: OCVIssueType;
}

/**
 * Documentation
 * OCV ecosystem: https://www.owiki.ms/wiki/OCV
 * Petro API: http://aka.ms/PetrolAPI
 */

const HITS_OCV_APP_ID = 2162;

export function useCreateIssue() {
	const { myRoles, myProfile } = useUserProfileContext();

	return useCallback(
		async (claim: CreateIssueClaim) => {
			const audience = myRoles?.map((role) => roleMeta.get(role)?.displayName).join(", ") ?? "Unknown audience";
			const loggableUserId = myProfile ? `a:${myProfile.user.directoryObjectId}` : "Unknown id"; //Refer to prefix info https://www.owiki.ms/wiki/OCV/Feedback_SDK_Web_Common
			const audienceGroup = myProfile?.researchGroups?.map((researchGroup) => researchGroup.name).join(", ") ?? "Unknown groups";
			const tenantId = env.aadTenantId;

			// xdomain.min.js replaces native FormData constructor with a custom implementation.
			// ref: https://github.com/jpillora/xdomain/blob/65c884925e4e67dc20066924066f5984d5e7eea9/src/vendor/xhook.js#L238
			// It stores the native FormData inside the xhook object
			// ref: https://github.com/jpillora/xdomain/blob/65c884925e4e67dc20066924066f5984d5e7eea9/src/vendor/xhook.js#L316
			const NativeFormData = ((window as any).xhook?.FormData ?? window.FormData) as new () => FormData;
			const formdata = new NativeFormData();

			const manifest = {
				appId: HITS_OCV_APP_ID,
				comment: claim.messageBody,
				email: claim.email,
				clientFeedbackId: crypto.randomUUID(),
				manifestType: "Sas",
				submitTime: new Date().toISOString(),
				source: "Client",
				type: claim.type,
				telemetry: {
					audience, // hits roles
					audienceGroup, // hits groups
					loggableUserId, // AAD object id
					osBuild: claim.commit,
					tenantId,
				},
				web: {
					sourcePageURI: location.href,
					sourcePageName: document.title,
					browser: navigator.userAgent,
				},
			};

			console.log(`[create-issue] support ticket submitted`, manifest);

			const manifestString = JSON.stringify(manifest);
			const manifestBlob = new Blob([manifestString], { type: "application/json" });

			formdata.append("Manifest", manifestBlob, "Manifest");

			if (claim.screenshot) {
				console.log(`[create-issue] screenshot attached`, claim.screenshot.name);
				formdata.append("Screenshot", claim.screenshot, claim.screenshot.name);
			}

			const requestOptions = {
				method: "POST",
				body: formdata,
			};

			const result = await fetch(`${env.petroApiBaseUrl}/feedback`, requestOptions).then((response) => {
				if (response.status !== 200) {
					// created {
					console.error("Unexpected response status:", response.status);
					throw Error("unexpected response");
				}

				return response.json();
			});

			return result;
		},
		[myRoles, myProfile]
	);
}
