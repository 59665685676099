import { gql } from "@apollo/client";

export const FIND_OR_ADD_USER = gql`
	mutation FindOrAddUser($args: FindOrAddUserArgs) {
		findOrAddUser(args: $args) {
			hitsRole
			memberships {
				researchGroup {
					id
					imageURL
					name
					preferredProgram {
						id
						name
					}
				}
				assignmentRole
				membershipDepth
			}
			user {
				id
				alias
				displayName
				directoryObjectId
			}
		}
	}
`;

export enum MemberAssignmentRole {
	Manager,
	OrgMember,
	AdditionalMember,
	GroupAdmin,
}

export interface Membership {
	researchGroup: ResearchGroup;
	assignmentRole: MemberAssignmentRole[];
	membershipDepth: null | number;
}

export interface ResearchGroup {
	id: number;
	name: string;
	imageURL: null | string;
	preferredProgram: null | {
		id: number;
		name: string;
	};
}

export interface FindOrAddUserInput {
	args: {
		userAlias: string;
		directoryObjectId: string;
	};
}

export interface FindOrAddUserOutput {
	findOrAddUser: {
		hitsRole: number;
		user: {
			id: number;
			alias: string;
			displayName: string;
			directoryObjectId: string;
		};
		memberships: Membership[];
	};
}
