import { HubEntityType } from "@hits/rest-api-types";
import { entityMeta } from "./entity-meta";

export function getHubUrl(entityType: HubEntityType, tagName: string) {
	const entityTypeSlug = entityMeta.get(entityType)?.slugName;
	const itemNameSlug = tagName.toLocaleLowerCase();

	if (!entityTypeSlug) {
		console.error("Provided entity is not a hub entity. Home url is returned");
		return "/";
	}

	return `/${entityTypeSlug}/${itemNameSlug}`;
}
