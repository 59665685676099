import { useApolloClient } from "@apollo/client";
import { ContextualMenu, ContextualMenuItemType, DirectionalHint, FontIcon, IContextualMenuItem, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SEARCHBOX_HISTORY_ITEMS } from "../../apis/queries/searchbox-history-items";
import { useAuthContext } from "../../contexts/auth-context";
import { useCustomerServiceDialogContext } from "../../contexts/customer-service-dialog-context";
import { useSiteSearchContext } from "../../contexts/site-search-context";
import { TrackerContextProvider } from "../../contexts/tracker-context";
import { useUserPhotosContext } from "../../contexts/user-photos-context";
import { useUserProfileContext } from "../../contexts/user-profile-context/user-profile-context";
import { hideInPrint } from "../../styles/02-tools/hide-in-print";
import { authService } from "../../utils/auth-service";
import { env } from "../../utils/env";
import { roleMeta } from "../../utils/role-meta";
import { AboutDialog } from "./about-dialog/about-dialog";
import { CustomerServiceDialog, TopLevelType } from "./customer-service-dialog/customer-service-dialog";
import { DROPDOWN_PAGE_VIEW_LIMIT } from "./search-box/dropdown-history";
import { SearchBox } from "./search-box/search-box";
import { useMockData } from "./use-mock-data";

export interface AppHeaderProps {
	className?: string;
}

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
	const authContext = useAuthContext();
	const siteSearchContext = useSiteSearchContext();
	const { myPhotoUrl } = useUserPhotosContext();
	const { myProfile, myRoles, toggleEmulatedRole, resetEmulatedRoles, isEmulatingRole, roleBasedFeatureFlags } = useUserProfileContext();
	const navigate = useNavigate();
	const mockData = useMockData();
	const apolloClient = useApolloClient();

	// help menu
	const helpMenuTriggerRef = useRef(null);
	const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);

	// overflow menu
	const overflowMenuTriggerRef = useRef(null);
	const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);

	// me menu
	const meMenuTriggerRef = useRef(null);
	const [isMeMenuOpen, setIsMeMenuOpen] = useState(false);

	// create issue dialog
	const { open: openCustomerServiceDialog, isOpen: isCustomerServiceDialogOpen, topLevelType, initialContent, onClose } = useCustomerServiceDialogContext();

	// about dialog
	const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false);

	// track popup windows disabled state
	const [popupsDisabled, setIsPopupsDisabled] = useState(false);

	const helpMenuItems: IContextualMenuItem[] = [
		{
			key: "contact",
			text: "Contact Helpdesk",
			onClick: () => openCustomerServiceDialog(TopLevelType.SupportRequest),
		},
		{
			key: "feedback",
			text: "Provide feedback",
			onClick: () => openCustomerServiceDialog(TopLevelType.Feedback),
		},
		{
			key: "doc",
			text: "Documentation",
			href: "https://dev.azure.com/hits-users-wiki/documentation/_wiki/wikis/documentation.wiki/1/HITS-Community-Wiki",
			target: "_blank",
		},
		{
			key: "divider_1",
			itemType: ContextualMenuItemType.Divider,
		},
		{
			key: "legal",
			text: "Legal",
			subMenuProps: {
				items: [
					{
						key: "privacy-and-cookies",
						text: "Privacy & cookies",
						href: "http://go.microsoft.com/fwlink/?LinkId=521839",
					},
					{
						key: "data-protection-notice",
						text: "Data Protection Notice",
						href: "http://go.microsoft.com/fwlink/?LinkId=518021",
					},
				],
			},
		},
		{
			key: "divider_2",
			itemType: ContextualMenuItemType.Divider,
		},
		{
			key: "about",
			text: "About",
			onClick: () => {
				setIsAboutDialogOpen(true);
			},
		},
	];

	const overFlowMenuItems: IContextualMenuItem[] = [
		{
			key: "preferences",
			text: "Preferences",
			iconProps: {
				iconName: "Settings",
			},
			onClick: () => navigate("/my/preferences"),
		},
		{
			key: "help",
			text: "Help",
			iconProps: {
				iconName: "Help",
			},
			subMenuProps: {
				items: helpMenuItems,
			},
		},
	];

	const handleClickWithoutClosing = useCallback((fn: () => any) => {
		return (e: React.MouseEvent | React.KeyboardEvent | undefined) => {
			// Fluent UI allows us to call preventDefault() in order to keep a context menu open after onClick
			e?.preventDefault();
			fn();
		};
	}, []);

	const prefetchRecentHistory = useCallback(() => {
		if (!myProfile) return;

		apolloClient.query({
			query: SEARCHBOX_HISTORY_ITEMS,
			variables: {
				args: {
					userId: myProfile.user.id,
					limit: DROPDOWN_PAGE_VIEW_LIMIT,
				},
			},
		});
	}, [myProfile]);

	const emulateRoleOptions: IContextualMenuItem[] = useMemo(
		() => [
			...[...roleMeta.entries()].map((role) => ({
				key: role[1].displayName,
				text: role[1].displayName,
				canCheck: true,
				checked: myRoles?.includes(role[0]),
				onClick: handleClickWithoutClosing(() => toggleEmulatedRole?.(role[0])),
			})),
			{
				key: "divider_2",
				itemType: ContextualMenuItemType.Divider,
			},
			...(isEmulatingRole
				? [
						{
							key: "reset",
							text: "Reset",
							onClick: handleClickWithoutClosing(() => resetEmulatedRoles?.()),
						},
				  ]
				: []),
		],
		[myRoles, isEmulatingRole]
	);

	const meMenuItems: IContextualMenuItem[] = useMemo(
		() => [
			{
				key: "my-contributions",
				text: "My content",
				onClick: () => navigate("/my/contributions/drafts"),
			},
			{
				key: "favorites",
				text: "Favorites",
				onClick: () => navigate("/my/favorites"),
			},
			{
				key: "history",
				text: "History",
				onClick: () => navigate("/my/history"),
			},
			{
				key: "queries",
				text: "Queries",
				href: "/search/advanced",
			},
			...(isEmulatingRole || roleBasedFeatureFlags?.isAdminDevToolEnabled
				? [
						{
							key: "divider-above-admin",
							itemType: ContextualMenuItemType.Divider,
						},
						{
							key: "admin-settings",
							text: "Admin portal",
							href: "/admin",
						},
						{
							key: "debug-tools",
							text: "Debug tools",
							subMenuProps: {
								directionalHint: DirectionalHint.leftTopEdge,
								items: [
									{
										key: "copy-creds",
										text: "Copy credentials",
										subMenuProps: {
											directionalHint: DirectionalHint.leftTopEdge,
											items: [
												{
													key: "api-token",
													text: "API token",
													onClick: () => {
														import("./debug-tools").then((debugTools) => {
															debugTools.getHitsApiToken(authContext);
														});
													},
												},
											],
										},
									},
									{
										key: "debug-presentation",
										text: "Debug presentation",
										subMenuProps: {
											directionalHint: DirectionalHint.leftTopEdge,
											items: [
												{
													key: "toggle-popups",
													text: "Disable popup windows",
													canCheck: true,
													checked: popupsDisabled,
													onClick: handleClickWithoutClosing(() => {
														import("./debug-tools").then((debugTools) => {
															const enabled = debugTools.togglePopups();
															setIsPopupsDisabled(!enabled);
														});
													}),
												},
											],
										},
									},
									{
										key: "graphql-playground",
										text: "GraphQL Playground",
										href: "/my/graphql-playground",
									},
									{
										key: "role-emulation",
										text: "Emulate roles",
										subMenuProps: {
											directionalHint: DirectionalHint.leftTopEdge,
											items: emulateRoleOptions,
										},
									},
									{
										key: "mock-data",
										text: "Use mock data",
										subMenuProps: {
											directionalHint: DirectionalHint.leftTopEdge,
											items: [
												{
													key: "no-drafts",
													text: "Empty draft list",
													onClick: handleClickWithoutClosing(() => mockData.mockNoDrafts()),
												},
												{
													key: "no-published",
													text: "Empty published list",
													onClick: handleClickWithoutClosing(() => mockData.mockNoPublished()),
												},
												{
													key: "divider-above-reset-mock-data",
													itemType: ContextualMenuItemType.Divider,
												},
												{
													key: "reset-mock-data",
													text: "Reset",
													onClick: handleClickWithoutClosing(() => mockData.resetAll()),
												},
											],
										},
									},
								],
							},
						},
				  ]
				: []),
			{
				key: "divider-above-sign-out",
				itemType: ContextualMenuItemType.Divider,
			},
			{
				key: "sign-out",
				text: "Sign out",
				onClick: () => authService.signOut(),
			},
		],
		[roleBasedFeatureFlags, isEmulatingRole, emulateRoleOptions, popupsDisabled]
	);

	return (
		<TrackerContextProvider scope={"app-header"}>
			<StyledHeader className={props.className}>
				{!siteSearchContext.isHeaderTakeover && (
					<HomeLink as={Link} className="app-name" data-testid="app-name" to="/">
						{env.appTitle}
					</HomeLink>
				)}
				<SearchBox
					autoFocus={siteSearchContext.isHeaderTakeover}
					className={siteSearchContext.isHeaderTakeover ? "search-mode" : "non-search-mode"}
					onCancel={siteSearchContext.releaseHeader}
					onMouseOver={prefetchRecentHistory}
				/>
				{!siteSearchContext.isHeaderTakeover && (
					<>
						<TooltipHost content="Search">
							<StyledSquareButton className="search-button" onClick={siteSearchContext.takeoverHeader} aria-label="Search">
								<FontIcon iconName="Search" />
							</StyledSquareButton>
						</TooltipHost>

						<TooltipHost content="Preferences">
							<StyledSquareButton className="collapse-when-narrow" onClick={() => navigate("/my/preferences")} aria-label="Preferences">
								<FontIcon iconName="Settings" />
							</StyledSquareButton>
						</TooltipHost>

						<TooltipHost content="Get help">
							<StyledSquareButton className="collapse-when-narrow" ref={helpMenuTriggerRef} onClick={() => setIsHelpMenuOpen(true)} aria-label="Get help">
								<FontIcon iconName="Help" />
							</StyledSquareButton>
						</TooltipHost>

						<ContextualMenu
							items={helpMenuItems}
							hidden={!isHelpMenuOpen}
							target={helpMenuTriggerRef}
							onItemClick={() => setIsHelpMenuOpen(false)}
							onDismiss={() => setIsHelpMenuOpen(false)}
						/>

						<TooltipHost content="More">
							<StyledSquareButton className="collapse-container" ref={overflowMenuTriggerRef} onClick={() => setIsOverflowMenuOpen(true)} aria-label="More">
								<FontIcon iconName="More" />
							</StyledSquareButton>
						</TooltipHost>

						<ContextualMenu
							items={overFlowMenuItems}
							hidden={!isOverflowMenuOpen}
							target={overflowMenuTriggerRef}
							onItemClick={() => setIsOverflowMenuOpen(false)}
							onDismiss={() => setIsOverflowMenuOpen(false)}
						/>

						<TooltipHost content="App menu">
							<StyledSquareButton ref={meMenuTriggerRef} onClick={() => setIsMeMenuOpen(true)} aria-label="App menu">
								{authContext.account?.name && (
									<Persona hidePersonaDetails text={authContext.account?.name} imageUrl={myPhotoUrl || undefined} size={PersonaSize.size32} />
								)}
							</StyledSquareButton>
						</TooltipHost>

						<ContextualMenu
							items={meMenuItems}
							hidden={!isMeMenuOpen}
							target={meMenuTriggerRef}
							onItemClick={() => setIsMeMenuOpen(false)}
							onDismiss={() => setIsMeMenuOpen(false)}
						/>
					</>
				)}
				{isCustomerServiceDialogOpen && <CustomerServiceDialog initialContent={initialContent} topLevelType={topLevelType} onClose={onClose} />}
				<AboutDialog hidden={!isAboutDialogOpen} onClose={() => setIsAboutDialogOpen(false)} />
			</StyledHeader>
		</TrackerContextProvider>
	);
};

const HomeLink = styled.a`
	display: inline-flex;
	height: 100%;
	align-items: center;
`;

const StyledSquareButton = styled.button`
	cursor: pointer;
	background-color: transparent;
	border: none;
	width: 48px;
	height: 48px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #003a6e;
	}

	.ms-Icon {
		font-size: 16px;
		color: white;
	}

	text-decoration: none;
`;

const StyledHeader = styled.header`
	z-index: 100;
	background-color: #005a9e;
	height: 48px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	${hideInPrint}

	.app-name {
		margin-left: 2rem;
		margin-right: auto;
		font-weight: 600;
		color: white;
		font-size: 16px;
		text-decoration: none;
	}

	.icon-button {
		cursor: pointer;
		background-color: transparent;
		border: none;
		width: 48px;
		height: 48px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		.ms-Icon {
			font-size: 16px;
			color: white;
		}
	}

	.icon-button:hover {
		background-color: #003a6e;
	}

	.collapse-when-narrow {
		display: none;

		@media screen and (min-width: 60rem) {
			display: inline-flex;
		}
	}

	@media screen and (min-width: 60rem) {
		.collapse-container {
			display: none;
		}
	}

	.non-search-mode {
		display: none;
	}

	.search-mode {
		display: inline-flex;
	}

	@media screen and (min-width: 40rem) {
		.non-search-mode {
			display: inline-flex;
		}
		.search-button {
			display: none;
		}
	}
`;
