import type { IFlagManager } from "./flag-manager-types";

const FLAGS_LOCAL_STORAGE_KEY = "hits-client-flags";

/**
 * For simplicity, we load the flags at most once per session.
 * After setting/unsetting/clearing flag(s), you need to reload the page
 * for it to take effect
 */
export class FlagManager<T extends Record<string, any>> implements IFlagManager<T> {
	private inMemFlags?: T;

	get(flag: keyof T) {
		const flags = this.ensureInMemFlags();
		return flags[flag];
	}

	getAll() {
		const flags = this.ensureInMemFlags();
		return flags;
	}

	set(flag: keyof T, value: any) {
		const flags = this.ensureInMemFlags();
		flags[flag] = value;
		this.save(flags);
	}

	remove(flag: keyof T) {
		const flags = this.ensureInMemFlags();
		delete flags[flag];
		this.save(flags);
	}

	removeAll() {
		localStorage.removeItem(FLAGS_LOCAL_STORAGE_KEY);
	}

	private ensureInMemFlags(): T {
		if (!this.inMemFlags) {
			try {
				this.inMemFlags = JSON.parse(localStorage.getItem(FLAGS_LOCAL_STORAGE_KEY) ?? "{}");
				console.log(`[flags]`, this.inMemFlags);
			} catch (e) {
				console.error(`[flag] parsing error`);
				this.inMemFlags = {} as any;
			}
		}

		return this.inMemFlags!;
	}

	private save(flags: T) {
		localStorage.setItem(FLAGS_LOCAL_STORAGE_KEY, JSON.stringify(flags));
	}
}
