import { HubEntityType } from "@hits/rest-api-types";
import { entityTypes } from "../../../utils/entity-meta";

interface RawHubSuggestion {
	id: number;
	displayName: string;
	entityType: HubEntityType;
	tagName: string;
}

/**
 * Deduplicate and remove irrelevant suggestions
 */
export function normalizeHubSuggestions<T extends RawHubSuggestion>(rawSuggestions: T[], query: string): T[] {
	const lowerCasePhrase = query.toLocaleLowerCase();
	const normalizedItems = getUniqueSuggestions(rawSuggestions).filter((item) => isSuggestionRelevant(item, lowerCasePhrase));

	return normalizedItems;
}

function getUniqueSuggestions<T extends RawHubSuggestion>(suggestions: T[]): T[] {
	return [...new Set(suggestions.map((item) => item.id))].map((id) => suggestions.find((item) => item.id === id)!);
}

function isSuggestionRelevant<T extends RawHubSuggestion>(item: T, lowerCasePhrase: string): boolean {
	return (
		// non-people result: honor original result
		item.entityType !== entityTypes.user ||
		// people result: make sure we don't look into parenthetical string such as Joe Smith (Azure), or Megan Smith (SHE/HER), as well as any vendor company names
		item.displayName
			.replace(/\([^)]*\)/g, "")
			.toLocaleLowerCase()
			.includes(lowerCasePhrase) ||
		// and support alias by matching tagName
		item.tagName.toLocaleLowerCase().includes(lowerCasePhrase)
	);
}
