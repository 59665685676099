import { EntityType } from "@hits/rest-api-types";
import { tokens } from "../styles/tokens";
import { iconNames } from "./icon-names";

export interface EntityMeta {
	/**
	 * The name used in:
	 * - API endpoint
	 * - URL segments
	 **/
	slugName?: string;
	displayName?: string;
	displayNamePlural?: string;
	/** The name of an MDL icon that represents this entity */
	iconName?: string;
	/** Hex value for the color that the icon should be exclusively displayed in */
	iconColor?: string;
	/**
	 * Variable name used in DB
	 * Ref: Microsoft.Osg.Urt.Model/AttributeType.cs
	 **/
	attributeTypeName?: string;
}

interface EntityTypeObject {
	insight: EntityType.Insight;
	study: EntityType.Study;
	product: EntityType.Product;
	method: EntityType.Method;
	evidence: EntityType.Evidence;
	recommendation: EntityType.Recommendation;
	supplement: EntityType.Supplemental;
	collection: EntityType.Collection;
	resource: EntityType.Resource;
	topic: EntityType.Topic;
	notificationTypes: EntityType.NotificationTypes;
	researchGroup: EntityType.ResearchGroup;
	user: EntityType.User;
	contentHub: EntityType.ContentHub;
	note: EntityType.Note;
}

export const entityTypes: EntityTypeObject = {
	insight: 1,
	study: 2,
	product: 4,
	method: 9,
	evidence: 21,
	recommendation: 25,
	supplement: 28,
	collection: 32,
	resource: 51,
	topic: 31,
	notificationTypes: 56,
	researchGroup: 59,
	user: 62,
	contentHub: 63,
	note: 64,
};

let _entries: [EntityType, EntityMeta][] | undefined;

export interface EntityMetaEntry {
	entityType: EntityType;
	entityMeta: EntityMeta;
}
export function findEntityMeta(predicate: (entityMeta: EntityMeta) => boolean): EntityMetaEntry | null {
	if (!_entries) _entries = [...entityMeta.entries()];
	const foundEntry = _entries.find((entry) => predicate(entry[1]));
	if (!foundEntry) return null;

	return {
		entityType: foundEntry[0],
		entityMeta: foundEntry[1],
	};
}

export const entityMeta = new Map<EntityType, EntityMeta>([
	[
		entityTypes.insight,
		{
			slugName: "insight",
			displayName: "Insight",
			displayNamePlural: "Insights",
			iconName: iconNames.insight,
			iconColor: tokens.colors.organge10,
			attributeTypeName: "Insight",
		},
	],
	[
		entityTypes.study,
		{
			slugName: "study",
			displayName: "Study",
			displayNamePlural: "Studies",
			iconName: iconNames.study,
			attributeTypeName: "Study",
		},
	],
	[
		entityTypes.product,
		{
			slugName: "product",
			displayName: "Product",
			displayNamePlural: "Products",
			iconName: iconNames.product,
			attributeTypeName: "Product",
		},
	],
	[
		entityTypes.method,
		{
			slugName: "method",
			displayName: "Method",
			displayNamePlural: "Methods",
			iconName: iconNames.method,
			attributeTypeName: "Method",
		},
	],
	[
		entityTypes.evidence,
		{
			slugName: "attachment",
			displayName: "Attachment",
			displayNamePlural: "Attachments",
			iconName: iconNames.attachment,
		},
	],
	[
		entityTypes.recommendation,
		{
			slugName: "recommendation",
			displayName: "Recommendation",
			displayNamePlural: "Recommendations",
			iconName: iconNames.recommendation,
			iconColor: tokens.colors.cyan10,
			attributeTypeName: "Recommendation",
		},
	],
	[
		entityTypes.supplement,
		{
			slugName: "section",
			displayName: "Section",
			displayNamePlural: "Sections",
			iconName: iconNames.section,
		},
	],
	[
		entityTypes.collection,
		{
			slugName: "collection",
			displayName: "Collection",
			displayNamePlural: "Collections",
			iconName: iconNames.collection,
			attributeTypeName: "Collection",
		},
	],
	[
		entityTypes.resource,
		{
			slugName: "resource",
			displayName: "Resource",
			displayNamePlural: "Resources",
			iconName: iconNames.resource,
			attributeTypeName: "Resource",
		},
	],
	[
		entityTypes.topic,
		{
			slugName: "topic",
			displayName: "Topic",
			displayNamePlural: "Topics",
			iconName: iconNames.topic,
			attributeTypeName: "Topic",
		},
	],
	[entityTypes.notificationTypes, {}],
	[
		entityTypes.researchGroup,
		{
			slugName: "group",
			displayName: "Group",
			displayNamePlural: "Groups",
			iconName: iconNames.researchGroup,
			attributeTypeName: "ResearchGroup",
		},
	],
	[
		entityTypes.user,
		{
			slugName: "person",
			displayName: "Person",
			displayNamePlural: "People",
			iconName: iconNames.user,
			attributeTypeName: "Researcher",
		},
	],
	[
		entityTypes.contentHub,
		{
			displayName: "Hub",
			displayNamePlural: "Hubs",
			iconName: iconNames.hub,
			attributeTypeName: "ContentHub",
		},
	],
	[
		entityTypes.note,
		{
			slugName: "note",
			displayName: "Customer Note",
			displayNamePlural: "Customer Notes",
			iconName: "Feedback",
			attributeTypeName: "Note",
		},
	],
]);
