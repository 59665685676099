import styled from "styled-components";
import { tokens } from "../../../styles/tokens";

// Make sure the element is already on the page before setting any message
// Screenreaders can ignore the initial content if it appears at the same time
// as the initial rendering.
export const ExceptionMessage = styled.div`
	margin: var(--item-vertical-padding) var(--item-side-padding);
	color: ${tokens.colors.gray160};
	font-size: 14px;

	&:empty {
		display: contents; // when empty, make sure it does not take up space in grid
	}
`;
