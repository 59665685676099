import { useEffect, useState } from "react";

export function useIsOffline() {
	const [isOffline, setIsOffline] = useState(!navigator.onLine);

	useEffect(() => {
		const handleDisconnect = setIsOffline.bind(null, true);
		window.addEventListener("offline", handleDisconnect);
		return () => window.removeEventListener("offline", handleDisconnect);
	}, []);

	useEffect(() => {
		const handleReconnect = setIsOffline.bind(null, false);
		window.addEventListener("online", handleReconnect);
		return () => window.removeEventListener("online", handleReconnect);
	}, []);

	return isOffline;
}
