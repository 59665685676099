import styled from "styled-components";
import { palette } from "../../../styles/theme";

export const DebugInfoAccordion = styled.details`
	border-radius: 4px;
	overflow: hidden;
`;

export const DebugInfoToggle = styled.summary`
	color: ${palette.neutralSecondary};
	cursor: pointer;

	&:hover {
		color: ${palette.neutralPrimary};
	}
`;

export const DebugInfoDump = styled.pre`
	background: white;
	border-radius: 4px;
	margin: 0;
	margin-top: 8px;
	padding: 16px;
	overflow-x: auto;
`;
