import { useMutation } from "@apollo/client";
import { HitsRole } from "@hits/rest-api-types";
import React, { createContext, Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FIND_OR_ADD_USER, FindOrAddUserInput, FindOrAddUserOutput, Membership, ResearchGroup } from "../../apis/mutations/find-or-add-user";
import { AppLoadingIndicator } from "../../components/app-root/app-loading-indicator";
import { HitsIntroParagraph } from "../../components/errro-page/snippets";
import { DebugInfoAccordion, DebugInfoDump, DebugInfoToggle } from "../../components/shared/debug-info/debug-info";
import { RestylableLink } from "../../components/shared/link/link";
import { Spacer } from "../../components/shared/spacer/spacer";
import { palette } from "../../styles/theme";
import { appInsights } from "../../utils/app-insights";
import { authService } from "../../utils/auth-service";
import { getPowersOfTwoArray } from "../../utils/bitwise";
import { hitsRoles } from "../../utils/role-meta";
import { useAuthContext } from "../auth-context";
import { getRoleBasedFeatureFlags, RoleBasedFeatureFlags } from "./get-feature-flags";

const ErrorPage = React.lazy(() => import("../../components/errro-page/error-page"));

export interface MyProfile {
	hitsRole: number;
	researchGroups?: ResearchGroup[];
	user: {
		id: number;
		alias: string;
		displayName: string;
		directoryObjectId: string;
	};
	memberships: Membership[];
}

export interface UserProfilesContextType {
	myProfile?: MyProfile | null;
	myRoles?: HitsRole[];
	roleBasedFeatureFlags?: RoleBasedFeatureFlags;
	toggleEmulatedRole?: (role: HitsRole) => void;
	resetEmulatedRoles?: () => void;
	isEmulatingRole?: boolean;
	isError?: boolean;
}

export const UserProfileContext = createContext<UserProfilesContextType>({});

export const UserProfileContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const [myRoles, setMyRoles] = useState<HitsRole[]>();
	const [roleBasedFeatureFlags, setRoleBasedFeatureFlags] = useState<RoleBasedFeatureFlags>();
	const { account } = useAuthContext();
	const [isEmulatingRole, setIsEmulatingRole] = useState(false);

	const [findOrAddUser, { data, error }] = useMutation<FindOrAddUserOutput, FindOrAddUserInput>(FIND_OR_ADD_USER);

	const [authError, setUnhandledError] = useState<any>();

	// get profile
	useEffect(() => {
		if (account) {
			// account.username is the same as userPrincipal name, which is the email address
			const userAlias = account.username.split("@")[0];

			findOrAddUser({
				variables: {
					args: {
						userAlias,
						directoryObjectId: account.homeAccountId,
					},
				},
			}).catch((error) => {
				// Do no include sensitive tokens
				const sanitizedAccount = {
					name: account.name,
					username: account.username,
					tenantId: account.tenantId,
					environment: account.environment,
					localAccountId: account.localAccountId,
					homeAccountId: account.homeAccountId,
				};
				if ((error?.message as string).startsWith("403")) {
					console.log("[user-profile] User blocked");
					appInsights.trackEvent({ name: "Auth denied" }, sanitizedAccount);
				} else {
					console.error("[user-profile] Error find or add user", error);
					appInsights.trackException({ exception: error }, sanitizedAccount);
					setUnhandledError(error);
				}
				setMyRoles([hitsRoles.none]);
			});
		}
	}, [account]);

	const initializeRole = useCallback(() => {
		if (data?.findOrAddUser.hitsRole === undefined) {
			return;
		}

		const roles = getPowersOfTwoArray(data.findOrAddUser.hitsRole);
		setMyRoles(roles);
	}, [data?.findOrAddUser.hitsRole]);

	// map profile to role
	useEffect(() => {
		initializeRole();
	}, [initializeRole]);

	// map roles to feature flags
	useEffect(() => {
		if (myRoles === undefined) return;

		setRoleBasedFeatureFlags(getRoleBasedFeatureFlags(myRoles));
	}, [myRoles]);

	const toggleEmulatedRole = useCallback((role: HitsRole) => {
		setMyRoles((previousRoles) => {
			if (previousRoles?.includes(role)) {
				return previousRoles.filter((existingRole) => existingRole !== role);
			} else if (previousRoles !== undefined) {
				return [...previousRoles, role];
			}

			return previousRoles;
		});
		setIsEmulatingRole(true);
	}, []);

	const resetEmulatedRoles = useCallback(() => {
		setIsEmulatingRole(false);
		initializeRole();
	}, [initializeRole]);

	const isError = useMemo(() => error !== undefined, [error]);
	const isLoading = useMemo(() => !myRoles?.length, [myRoles]);

	const contextObject = {
		isEmulatingRole,
		isError,
		myProfile: data?.findOrAddUser,
		myRoles,
		roleBasedFeatureFlags,
		toggleEmulatedRole,
		resetEmulatedRoles,
	};

	return (
		<UserProfileContext.Provider value={contextObject}>
			{isLoading && <AppLoadingIndicator loadingReason="Authenticating…" />}
			{!isLoading && !roleBasedFeatureFlags?.isAppVisible && (
				<Suspense fallback={<AppLoadingIndicator loadingReason="Authenticating…" />}>
					{authError ? (
						<ErrorPage
							pageTitle="Error signing into HITS"
							messageDetails={
								<>
									{HitsIntroParagraph}
									<Spacer $size={16} />
									<p>
										Something went wrong during sign-in. It is probably an issue with HITS. Please try again later or contact{" "}
										<Link href="mailto:HITS911@microsoft.com">HITS911@microsoft.com</Link> for additional help.
									</p>
									<Spacer $size={16} />
									<DebugInfoAccordion open={true}>
										<DebugInfoToggle>Error details</DebugInfoToggle>
										<DebugInfoDump>{JSON.stringify(authError, replaceError, 2)}</DebugInfoDump>
									</DebugInfoAccordion>
									<Spacer $size={16} />
									<DebugInfoAccordion>
										<DebugInfoToggle>Account information</DebugInfoToggle>
										<DebugInfoDump>{JSON.stringify(account, undefined, 2)}</DebugInfoDump>
									</DebugInfoAccordion>
								</>
							}
						/>
					) : (
						<ErrorPage
							pageTitle="Getting access to HITS"
							messageDetails={
								<>
									{HitsIntroParagraph}
									<Spacer $size={16} />
									{account && (
										<p>
											Your account{" "}
											<AccountIdentifier>
												{account.name} ({account.username})
											</AccountIdentifier>{" "}
											is not set up for viewing content in HITS. If you want to use a different account, please{" "}
											<Link onClick={() => authService.signOut()}>sign out</Link> first. You may contact{" "}
											<Link href="mailto:HITS911@microsoft.com">HITS911@microsoft.com</Link> for additional help.
										</p>
									)}
									<Spacer $size={16} />
									<DebugInfoAccordion>
										<DebugInfoToggle>Account information</DebugInfoToggle>
										<DebugInfoDump>{JSON.stringify(account, undefined, 2)}</DebugInfoDump>
									</DebugInfoAccordion>
								</>
							}
						/>
					)}
				</Suspense>
			)}
			{!isLoading && roleBasedFeatureFlags?.isAppVisible && props.children}
		</UserProfileContext.Provider>
	);
};

export function useUserProfileContext() {
	return useContext(UserProfileContext);
}

const AccountIdentifier = styled.strong`
	font-weight: 600;
`;

const Link = styled(RestylableLink)`
	color: ${palette.themeDarkAlt};
	cursor: pointer;
`;

function replaceError(key: string, value: any) {
	if (value instanceof Error) {
		const error: Record<string, any> = {};

		Object.getOwnPropertyNames(value).forEach((propName) => {
			error[propName] = (value as any)[propName];
		});

		return error;
	}

	return value;
}
