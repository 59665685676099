import { gql } from "@apollo/client";
import { FavoriteEntityType } from "@hits/rest-api-types";
import { DisplayFavorite } from "../queries/favorites";

export const FAVORITE_REPORT = gql`
	mutation FavoriteReport($args: FavoriteReportArgs!) {
		favoriteReport(args: $args) {
			createdOn
			entityId
			entityType
			relatedEntity {
				title
				isPublished
				family {
					researchers {
						alias
						directoryObjectId
						displayName
					}
				}
			}
		}
	}
`;

export interface FavoriteReportInput {
	args: {
		entityId: number;
		entityType: FavoriteEntityType;
	};
}

export interface FavoriteReportOutput {
	favoriteReport: DisplayFavorite;
}
