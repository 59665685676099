const shadows = {
	/** card, grid item thumbnail */
	depth4: `0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)`,
	depth4Inset: `inset 0 1.6px 3.6px 0 rgba(0,0,0,.132), inset 0 0.3px 0.9px 0 rgba(0,0,0,.108)`,
	/** command bar, command dropdown, contenxt menu */
	depth8: `0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)`,
	/** callout, search dropdown, hover card, tooltip */
	depth16: `0 6.4px 14.4px 0 rgba(0,0,0,.132), 0 1.2px 3.6px 0 rgba(0,0,0,.108)`,
	/** panel, dialog */
	depth64: `0 25.6px 57.6px 0 rgba(0,0,0,.22), 0 4.8px 14.4px 0 rgba(0,0,0,.18)`,
};

const corners = {
	/** buttons */
	radius2: `2px`,
	/** cards */
	radius4: `4px`,
};

/** @deprecated use `palette` instead */
const colors = {
	/** @deprecated use `palette.neutralLighterAlt */
	gray10: "#faf9f8",
	/** @deprecated use `palette.neutralLighter */
	gray20: "#f3f2f1",
	/** @deprecated use `palette.neutralLight */
	gray30: "#edebe9",
	gray40: "#e1dfdd",
	gray50: "#d2d0ce",
	/** disabled text */
	gray90: "#a19f9d",
	/** @deprecated use `palette.neutralSecondary */
	gray130: "#605e5c",
	gray140: "#484644",
	gray150: "#3b3a39",
	/** @deprecated use `palette.neutralPrimary */
	gray160: "#323130",
	/** Recommendation */
	cyan10: "#00b7c3",
	cyanBlue10: "#0078d4",
	/** Insight */
	organge10: "#ffaa44",
	success: "#107c10",
	error: "#a80000",
	lightBackground: {
		red: "#E6808A",
		green: "#79DB75",
		blue: "#83BEEC",
		lime: "#BAD80A",
		teal: "#30C6CC",
		orange: "#F7B189",
		pink: "#F495BF",
		purple: "#A992D4",
	},
};

const fonts = {
	system: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
};

const duration = {
	t100: "100ms",
};

export const tokens = {
	shadows,
	corners,
	colors,
	duration,
	fonts,
};
