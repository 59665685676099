import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { useLocalStorageWatcher } from "../../utils/use-local-storage-watcher";

// Refetch any queries that might be affected by page views from other tabs
export function usePageViewsCacheEviction() {
	const apolloClient = useApolloClient();

	// manually evict all the cache from the pageView query
	// reference: https://www.apollographql.com/docs/react/caching/garbage-collection/
	const evictCache = useCallback(() => {
		apolloClient.cache.evict({ fieldName: "pageViews" });
		apolloClient.cache.gc();
	}, []);

	useLocalStorageWatcher({ key: "latest-visit-timestamp", onChange: evictCache });
}
