import { EntityType, EventType, HitsRole } from "@hits/rest-api-types";
import { useMemo, useRef } from "react";
import { PresentationConfiguration } from "../components/presentation-page/presentation-config-hooks";
import { OmniboxMode } from "../components/report-page/link-dialog/link-dialog";
import { Filters } from "../components/search-page/use-managed-url";
import { useTrackerContext } from "../contexts/tracker-context";
import { appInsights } from "./app-insights";
import { LinkDialogMode } from "./bifrost-types";
import { eventTypes } from "./event-meta";
import { useSessionPromoCode } from "./use-session-promo-code";

// Please sort A-Z

export const useTrackAddFollow = () => useEventTrackerFactory<AddDiscoverInterest>("add-follow");
export const useTrackAddSearchPageFilter = () => useEventTrackerFactory<AddSearchPageFilter>("add-search-page-filter", eventTypes.addSearchPageFilter);
export const useTrackCreate = () => useEventTrackerFactory<CreateSummary>("create");
export const useTrackEndDiscoverSession = () => useEventTrackerFactory<DiscoverSessionSummary>("end-discover-session", eventTypes.endDiscoverSession);
export const useTrackEndSearchBoxSession = () => useEventTrackerFactory<SearchBoxSessionSummary>("end-search-box-session", eventTypes.endSearchBoxSession);
export const useTrackEndSearchPageSession = () => useEventTrackerFactory<SearchPageSessionSummary>("end-search-page-session", eventTypes.endSearchPageSession);
export const useTrackLinkDialogAction = () => useEventTrackerFactory<LinkDialogAction>("link-dialog-action");
export const useTrackLoadFeed = () => useEventTrackerFactory<LoadFeed>("load-feed");
export const useTrackOpenFeedContent = () => useEventTrackerFactory<OpenFeedContent>("open-content", eventTypes.openContent);
export const useTrackOpenHomeContent = () => useEventTrackerFactory<OpenHomeContent>("open-content", eventTypes.openContent);
export const useTrackOpenHubPageContent = () => useEventTrackerFactory<OpenHubPageContent>("open-content", eventTypes.openContent);
export const useTrackOpenRecommendedExplorations = () => useEventTrackerFactory<OpenBaseContent>("open-content", eventTypes.openContent);
export const useTrackOpenSearchBoxContent = () => useEventTrackerFactory<OpenSearchBoxContent>("open-content", eventTypes.openContent);
export const useTrackOpenSearchPageContent = () => useEventTrackerFactory<OpenSearchPageContent>("open-content", eventTypes.openContent);
export const useTrackPresentationInvalidReconciliation = () => useEventTrackerFactory<PresentationInvalidReconciliation>("presentation-reconciliation-invalid");
export const useTrackTimeSpent = () => useMetricTrackerFactory<BaseMetricDataObject>("TimeSpent");

export interface AddDiscoverInterest {
	tagName: string;
	entityType: EntityType;
}

export interface AddSearchPageFilter {
	facetName: string;
	selectedOptionIndex?: number;
	availableOptionCount?: number;
	resultCountForOption?: number;
	customData?: any;
}

export type ComponentDuration = {
	duration: number;
};

export interface CreateSummary {
	entityType?: number;
	templateId?: number;
	creatorRoles: HitsRole[];
	duration: number;
}

export type DiscoverExitAction = "open" | "ignore" | "browse";

export interface DiscoverSessionSummary {
	scrollDistance: number;
	openItemCount: number;
	loadMoreTripCount: number;
	exitAction: DiscoverExitAction;
	isDiscoveryPrioritized: boolean;
	duration: number;
}

export interface LinkDialogAction {
	dialogMode: LinkDialogMode;
	omniboxMode: OmniboxMode;
	isSuccess: boolean;
}

export interface OpenBaseContent {
	entityId?: number;
	entityType?: EntityType;
	kind?: string;
	index?: number;
}

export interface OpenHomeContent extends OpenBaseContent {
	openInEditor?: boolean;
}

/**
 * Events from the hub result card
 * "explore" - open the primary matching hub page
 * "entity" - open report or person from the primary matching hub details
 * "suggested-page" - open additional hub page results (secondary/partial match)
 */
export type SearchPageContentKindHub = "explore" | "entity" | "suggested-page";
export type SearchPageContentKindRanked = "ranked" | "ranked-child";
export type SearchPageContentKindNotesBundle = "notes-bundle-item";

export interface OpenSearchPageContent extends OpenBaseContent {
	kind: SearchPageContentKindHub | SearchPageContentKindRanked | SearchPageContentKindNotesBundle;
	page: number;
	query: string;
	filters: Filters;
	resultCount: number;
	isCurated: boolean;
}

export interface OpenHubPageContent extends OpenBaseContent {
	kind: "hub" | "view-all-recent" | "report" | "hub-settings" | "get-link" | "child-category" | "parent-category" | "statistics";
}

export interface OpenFeedContent extends OpenBaseContent {
	index: number;
	isDiscoverPrioritized: boolean;
}

export type SearchBoxContentKind = "entity" | "history" | "all" | "suggested-phrase" | "advanced-search";

export interface OpenSearchBoxContent extends OpenBaseContent {
	kind: SearchBoxContentKind;
}

export type SearchBoxExitAction = "open" | "commit" | "abandon";

export interface SearchBoxSessionSummary {
	exitQuery: string | null;
	exitAction: SearchBoxExitAction;
	duration: number;
}

export type SearchPageExitAction = "open" | "abandon";

export interface SearchPageSessionSummary {
	attemptedQueries: string[];
	attemptedQueryCount: number;
	successfulQueryCount: number; // queries that lead to at least one item opened
	failedQueryCount: number; // queries that lead to no item opened
	querySuccessRate: number;
	duration: number;
	openItemCount: number;
	exitQuery: string;
	exitResultCount: number | null;
	exitAction: SearchPageExitAction;
	exitFilters: Filters;
	exitFilterCount: number;
}

export interface LoadFeed {
	isInitial?: boolean;
	requestCount: number;
	overflowItemCount: number;
	duration?: number;
}

export interface PresentationInvalidReconciliation {
	entityId: string;
	flattenedNodes: { id: string }[];
	cachedConfig: PresentationConfiguration;
}

/** ========= INTERNAL BELOW ========= */

/** Create a tracker function that automatically detects the calling context and injects common metadata */
function useEventTrackerFactory<T = unknown>(name: string, eventType?: EventType) {
	const { path } = useTrackerContext();

	const promoCode = useSessionPromoCode();

	return useRef((data?: BaseDataObject & T) => {
		const { scope, ...restOfData } = data || ({} as BaseDataObject);
		const newPath = scope ? [...path, scope] : path;
		const pathString = newPath.join("/");
		const uri = location.href;
		const webPackageVersion = process.env.WEB_PACKAGE_VERSION;
		const commitHash = process.env.COMMIT_HASH;

		appInsights.trackEvent({ name }, { ...restOfData, path: pathString, uri, promoCode, eventType, webPackageVersion, commitHash });
	}).current;
}

/** Create a matric tracker function that automatically detects the calling context and injects common metadata */
function useMetricTrackerFactory<T = unknown>(name: string) {
	const { path } = useTrackerContext();

	return useMemo(
		() => (data?: BaseMetricDataObject & T) => {
			const { scope, average, ...restOfData } = data || ({} as BaseMetricDataObject);
			const newPath = scope ? [...path, scope] : path;
			const pathString = newPath.join("/");
			appInsights.trackMetric({ name, average }, { ...restOfData, path: pathString });
		},
		[path]
	);
}

interface BaseDataObject {
	/** Use this parameter to append new scope to the tracking path in addition to the scopes already acquired from the context */
	scope?: string;
}

interface BaseMetricDataObject {
	/** Use this parameter to append new scope to the tracking path in addition to the scopes already acquired from the context */
	scope?: string;
	average: number;
}
