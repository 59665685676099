export type FileSizeUnit = "Bytes" | "KB" | "MB" | "GB" | "TB" | "PB" | "EB" | "ZB" | "YB";
const sizes: FileSizeUnit[] = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

/**
 * Convert byte size to human readable units
 * Based on : https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
export function convertFileSize(byteSize: number, targetUnit: FileSizeUnit = "MB"): number {
	if (byteSize === 0) return 0;

	const k = 1024;

	const i = sizes.indexOf(targetUnit);

	return byteSize / Math.pow(k, i);
}
