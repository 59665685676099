import { useQuery } from "@apollo/client";
import {
	ChoiceGroup,
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IChoiceGroupOption,
	ITextField,
	PrimaryButton,
	Spinner,
	TextField,
} from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { META, Meta } from "../../../apis/queries/meta";
import { OCVIssueType, useCreateIssue } from "../../../apis/use-create-issue";
import { useAuthContext } from "../../../contexts/auth-context";
import { tokens } from "../../../styles/tokens";
import { appInsights } from "../../../utils/app-insights";
import { SingleImagePicker } from "../../shared/single-image-picker/single-image-picker";

/** Props that are solely derived from service type */
interface TopLevelProps {
	title: string;
	details: string;
	inputLabel: string;
	successMessage: string;
	type?: OCVIssueType;
	subCategories?: SubCategoryType[];
}

interface SubCategoryProps {
	displayName: string;
	type: OCVIssueType;
}

enum SubmissionStatus {
	initial,
	submitting,
	error,
	success,
}

export enum TopLevelType {
	SupportRequest = "Support request",
	Feedback = "Feedback",
}

export enum SubCategoryType {
	Bug = "Bug",
	Suggestion = "Suggestion",
	Compliment = "Compliment",
}

const dialogConfigMap = new Map<TopLevelType | undefined, TopLevelProps>([
	[
		TopLevelType.SupportRequest,
		{
			title: "Contact Helpdesk",
			type: "Frown",
			details: "We will get in touch via email.",
			inputLabel: "Describe the issue",
			successMessage: "We have received your message. You will receive an email confirmation shortly. You may close the dialog now.",
		},
	],
	[
		TopLevelType.Feedback,
		{
			title: "Provide feedback",
			details: "The HITS team may reach out to you for more information.",
			inputLabel: "Share your feedback",
			successMessage: "We have received your feedback. Thank you for taking the time to help improve HITS. You may close the dialog now.",
			subCategories: [SubCategoryType.Bug, SubCategoryType.Suggestion, SubCategoryType.Compliment],
		},
	],
]);

const subCategoryMap = new Map<SubCategoryType, SubCategoryProps>([
	[SubCategoryType.Bug, { displayName: "🐞 Bug report", type: "Bug" }],
	[SubCategoryType.Suggestion, { displayName: "🙋‍♀️ Suggestion", type: "Idea" }],
	[SubCategoryType.Compliment, { displayName: "💖 Compliment", type: "Smile" }],
]);
export interface CustomerServiceDialogProps {
	topLevelType?: TopLevelType;
	initialContent?: string;
	onClose?: () => any;
}

/**
 * If you want to open dialog imperatively, use the CustomerServiceDialogContext and call open()
 * If you want to render the dialog declaratively, use <CustomerServiceDialog> element directly and pass in the props
 */

export const CustomerServiceDialog: React.FC<CustomerServiceDialogProps> = (props) => {
	const { topLevelType, onClose, initialContent } = props;
	const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>(SubmissionStatus.initial);
	const [messageBody, setMessageBody] = useState<string>();
	const { account } = useAuthContext();
	const createIssue = useCreateIssue();
	const messageInputRef = useRef<ITextField>(null);
	const [isValidationFailed, setIsValidationFailed] = useState(false);
	const [selectedSubCategoryType, setSelectedSubCategoryType] = useState<SubCategoryType>();
	const [subCategoryOptions, setSubCategoryOptions] = useState<IChoiceGroupOption[]>();
	const [topLevelProps, setTopLevelProps] = useState<TopLevelProps | undefined>(dialogConfigMap.get(topLevelType));
	const [screenshot, setScreenshot] = useState<File | null>(null);
	const { data: metaData } = useQuery<Meta>(META);

	const isSubmittingButtonVisible = [SubmissionStatus.initial, SubmissionStatus.submitting, SubmissionStatus.error].includes(submissionStatus);
	const isSuccessMessageVisible = submissionStatus === SubmissionStatus.success;
	const isErrorMessageVisible = submissionStatus === SubmissionStatus.error;
	const isSubmitting = submissionStatus === SubmissionStatus.submitting;

	// derive props from issue type
	useEffect(() => {
		const mappedProps = dialogConfigMap.get(topLevelType)!;
		setTopLevelProps(mappedProps);
	}, [topLevelType]);

	// set default sub category when issue type changes
	useEffect(() => {
		const subCategoryOptions: IChoiceGroupOption[] | undefined = topLevelProps?.subCategories?.map((subCategory) => ({
			key: subCategory,
			text: subCategoryMap.get(subCategory)!.displayName,
		}));
		setSubCategoryOptions(subCategoryOptions);
		setSelectedSubCategoryType(subCategoryOptions ? (subCategoryOptions[0].key as SubCategoryType) : undefined);
	}, [topLevelProps]);

	const onCloseInternal = useCallback(() => {
		// reset all fields only if the submission is sccessful. In other cases, user may want to re-use the content
		if (submissionStatus === SubmissionStatus.success) {
			setSubmissionStatus(SubmissionStatus.initial);
			setMessageBody("");
		}

		// always reset validation
		setIsValidationFailed(false);

		onClose?.();
	}, [submissionStatus]);

	const handleSubmit = useCallback(() => {
		if (!isFormValid()) return;

		setSubmissionStatus(SubmissionStatus.submitting);

		const issueFooter = [
			`\nSYSTEM INFORMATION`,
			`UserLocalTime: ${new Date().toLocaleString()}`,
			`Name: ${account?.name}`,
			`AISession: ${appInsights.context.session.id}`,
			`Build: ${process.env.BUILD_ID}`,
			`WebPackage: ${process.env.WEB_PACKAGE_VERSION}`,
			`APIServerPackage: ${metaData?.meta.packageVersion ?? "Unknown"}`,
			`APIServerNode: ${metaData?.meta.nodeVersion ?? "Unknown"}`,
			`APIServerFunctionsExtension: ${metaData?.meta.functionsExtensionVersionRequested ?? "Unknown"}`,
		].join("\n");

		const type = topLevelProps?.type ?? subCategoryMap.get(selectedSubCategoryType!)!.type;

		createIssue({
			messageBody: `${messageBody}\n\n${issueFooter}`,
			email: account?.username ?? "unknown-email",
			type,
			commit: `Commit: ${process.env.COMMIT_HASH}`,
			screenshot,
		})
			.then(() => {
				setSubmissionStatus(SubmissionStatus.success);
			})
			.catch(() => {
				setSubmissionStatus(SubmissionStatus.error);
			});
	}, [messageBody, account, appInsights, selectedSubCategoryType, topLevelProps, screenshot]);

	const isFormValid = useCallback(() => {
		if (!messageBody) {
			setIsValidationFailed(true);
			messageInputRef.current?.focus();
			return false;
		}

		return true;
	}, [messageBody]);

	useEffect(() => {
		if (!initialContent) {
			return;
		}

		setMessageBody(initialContent);

		// Update cursor after populating initial content
		// Because fluent text field will not auto update focus.
		// We wait for it to render (setTimeout) and manually move cursor to the end of the input
		window.setTimeout(() => {
			console.log(messageInputRef.current);
			messageInputRef.current?.setSelectionRange(initialContent.length, initialContent.length);
		}, 0);
	}, [initialContent]);

	return (
		<Dialog
			hidden={false}
			onDismiss={onCloseInternal}
			dialogContentProps={{
				type: DialogType.normal,
				title: topLevelProps?.title,
				closeButtonAriaLabel: "Close",
				subText: topLevelProps?.details,
			}}
			styles={{
				main: {
					width: "calc(100% - 32px)",
					selectors: {
						["@media (min-width: 480px)"]: {
							width: "calc(100% - 32px)",
							maxWidth: "initial",
						},
						["@media (min-width: 640px)"]: {
							width: "40rem",
						},
					},
				},
			}}
		>
			<StyledGrid>
				<TextField
					label={topLevelProps?.inputLabel}
					multiline
					rows={5}
					required
					disabled={isSubmitting}
					value={messageBody}
					autoAdjustHeight
					componentRef={messageInputRef}
					validateOnFocusIn={isValidationFailed}
					validateOnLoad={false}
					resizable={false}
					onGetErrorMessage={(v) => (v ? undefined : "Please fill in this field.")}
					onChange={(e) => setMessageBody((e.target as HTMLTextAreaElement).value)}
				/>
				{subCategoryOptions && (
					<ChoiceGroup
						selectedKey={selectedSubCategoryType}
						options={subCategoryOptions}
						onChange={(_, option) => setSelectedSubCategoryType(option?.key as SubCategoryType)}
						label="Categorize your feedback"
					/>
				)}
				<SingleImagePicker label="Screenshot" onImageChanged={(file) => setScreenshot(file)} image={screenshot} />
				{isSuccessMessageVisible && <StyledSuccessParagraph>{topLevelProps?.successMessage}</StyledSuccessParagraph>}
				{isErrorMessageVisible && <StyledErrorParagraph>Sorry, something went wrong. Please try again later.</StyledErrorParagraph>}
				{isSubmitting && <StyledSpinner as={Spinner} label="Submitting…" labelPosition="right" />}
			</StyledGrid>
			<DialogFooter>
				{isSubmittingButtonVisible && <PrimaryButton onClick={handleSubmit} text="Submit" disabled={isSubmitting} />}
				{submissionStatus === SubmissionStatus.success ? (
					<PrimaryButton onClick={onCloseInternal}>Done</PrimaryButton>
				) : (
					<DefaultButton onClick={onCloseInternal} text="Close" />
				)}
			</DialogFooter>
		</Dialog>
	);
};

const StyledGrid = styled.div`
	display: grid;
	grid-gap: 1rem;
`;

const StyledSuccessParagraph = styled.p`
	color: ${tokens.colors.success};
`;
const StyledErrorParagraph = styled.p`
	color: ${tokens.colors.error};
`;

const StyledSpinner = styled.div`
	justify-self: start;
`;
