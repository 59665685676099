import type { HitsClientFlags, HitsEvents } from "./bifrost-types";
import { EventHub } from "./event-hub";
import { FlagManager } from "./flag-manager";
import { getInitials } from "./get-initials";
import { textToColor } from "./text-to-color";

/**
 * Bifrost connects modular javascript from modern frontent with namespaced javascript from classic frontend

 * How to use it
 * 1. In modern frontend, import the `bifrost` const. As a side effect, it will expose itself on the window object
 * 2. In classic frontend, access it from the window object
 */
export const bifrost: IBifrost = {
	events: new EventHub<HitsEvents>(),
	flags: new FlagManager<HitsClientFlags>(),
	utils: {
		textToColor,
		getInitials,
	},
};

/**
 * This forces caller to explicitly import bifrost
 */
export function exposeBifrostOnWindow(bifrost: IBifrost, window: Window) {
	window.bifrost = bifrost;
}
