import { EventType } from "@hits/rest-api-types";

interface EventTypeObject {
	addSearchPageFilter: EventType.AddSearchPageFilter;
	endDiscoverSession: EventType.EndDiscoverSession;
	endSearchBoxSession: EventType.EndSearchBoxSession;
	endSearchPageSession: EventType.EndSearchPageSession;
	openContent: EventType.OpenContent;
}

export const eventTypes: EventTypeObject = {
	addSearchPageFilter: 31,
	endDiscoverSession: 32,
	endSearchBoxSession: 33,
	endSearchPageSession: 34,
	openContent: 35,
};
