import { FocusZone, FocusZoneDirection, FontIcon, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSiteSearchContext } from "../../../contexts/site-search-context";
import { tokens } from "../../../styles/tokens";
import { useOnClickOutside } from "../../../utils/use-on-click-outside";
import { useOnFocusOut } from "../../../utils/use-on-focus-out";
import { useSkipToMain } from "../../../utils/use-skip-to-main";
import { buildSearchUrl } from "../../search-page/build-search-url";
import { EMPTY_QUERY, useManagedUrl } from "../../search-page/use-managed-url";
import { DropdownFooter } from "./dropdown-footer";
import { DropdownHistory } from "./dropdown-history";
import { DropdownSuggestions } from "./dropdown-suggestions";
import { useSearchBoxAnalytics } from "./use-search-box-analytics";

export const DROPDOWN_ELEMENT_ID = "app-search-suggestions";

export interface SearchBoxProps {
	className?: string;
	autoFocus?: boolean;
	onCancel?: () => void;
	onMouseOver?: () => void;
}

export const SearchBox: React.FC<SearchBoxProps> = (props) => {
	const [isInputFocused, setIsInputFocused] = useState(false);
	const [displayQuery, setDisplayQuery] = useState<string>("");
	const trimmedQuery = displayQuery.trim();
	const rootRef = useRef<HTMLDivElement>(null);
	const siteSearchContext = useSiteSearchContext();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const { q } = useManagedUrl();
	const navigate = useNavigate();
	const { trackOpenDropdown, trackCloseDropdown, trackCommit, trackQueryUpdate, trackOpenFindResult } = useSearchBoxAnalytics();
	const isEmptyState = !trimmedQuery.length;

	/**
	 * Consider the url as source of truth.
	 * Keep in-memory query in sync with the url query
	 */
	useEffect(() => {
		const displayQuery = q === EMPTY_QUERY ? "" : q; // don't show the default `*` query on UI

		setDisplayQuery(displayQuery);
	}, [q]);

	useEffect(() => {
		if (props.autoFocus) {
			siteSearchContext.searchBoxRef.current!.focus();
		}
	}, [props.autoFocus]);

	useEffect(() => {
		isDropdownOpen ? trackOpenDropdown() : trackCloseDropdown();
	}, [isDropdownOpen]);

	const skipToMain = useSkipToMain();

	const onPassiveCancel = () => {
		if (props.onCancel) {
			props.onCancel();
		}

		setIsDropdownOpen(false);
	};

	useOnFocusOut(rootRef, onPassiveCancel);
	useOnClickOutside(rootRef, onPassiveCancel);

	const onInputFocus = () => {
		setIsInputFocused(true);
		setIsDropdownOpen(true);
	};
	const onInputBlur = () => setIsInputFocused(false);
	const onRootKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === "Escape") {
			siteSearchContext.searchBoxRef.current?.focus(); // when focus isn't in the input, bring it back first
			setDisplayQuery("");

			if (!displayQuery.length) {
				siteSearchContext.searchBoxRef.current!.blur();
				onPassiveCancel();
			}
		}
	};

	const allResultsLink = buildSearchUrl({ query: displayQuery ? displayQuery : EMPTY_QUERY });

	const onCommitSearch = (openInNewTab?: boolean) => {
		const effectiveQuery = trimmedQuery.length ? trimmedQuery : EMPTY_QUERY;
		const resultPageUrl = buildSearchUrl({ query: effectiveQuery });

		if (openInNewTab) {
			window.open(resultPageUrl);
			// don't close dropdown when user opens in new tab. This allows rapid opening of multiple items
		} else {
			navigate(resultPageUrl);
			setIsDropdownOpen(false);
			siteSearchContext.searchBoxRef.current?.blur();
			skipToMain();
		}

		trackCommit();
	};

	const onInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			onCommitSearch(e.ctrlKey);
		}
	};

	const onQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// TODO switch back to `e?.target.value.trimStart?.()` once https://github.com/swc-project/swc/issues/2063 is addressed
		const newQuery = e?.target.value.trimStart ? e.target.value.trimStart() : e?.target.value;
		setDisplayQuery(newQuery);
		trackQueryUpdate(newQuery);
	};

	const closeDropdown = useCallback(() => {
		siteSearchContext.searchBoxRef.current!.focus();
		setIsDropdownOpen(false);
		siteSearchContext.searchBoxRef.current!.blur();
	}, []);

	const handleOpenHistoryItem = useCallback((event: React.MouseEvent) => {
		trackOpenFindResult({ kind: "history" });
		if (!event.ctrlKey) {
			closeDropdown();
		}
	}, []);

	const handleOpenAllResult = useCallback(() => {
		trackOpenFindResult({ kind: "all" });
		closeDropdown();
	}, []);

	const handleOpenAdvancedSearch = useCallback(() => {
		trackOpenFindResult({ kind: "advanced-search" });
		closeDropdown();
	}, []);

	return (
		<StyledSearchWidget
			className={props.className}
			$isInputFocused={isInputFocused}
			$isSearchBoxStretched={isDropdownOpen}
			onKeyDown={onRootKeydown}
			onMouseOver={props.onMouseOver}
			ref={rootRef}
		>
			<FocusZone className="modern-search__focus-zone" isCircularNavigation={true} direction={FocusZoneDirection.vertical}>
				<FontIcon className="modern-search__search-icon" iconName="Search" />
				<SearchBoxInput
					onFocus={onInputFocus}
					onBlur={onInputBlur}
					type="text"
					role="combobox"
					aria-label="Search box. Suggestions appear as you type."
					autoComplete="off"
					ref={siteSearchContext.searchBoxRef}
					placeholder="Search insights and reports"
					data-testid="search-input"
					data-is-focusable={true}
					aria-expanded={isDropdownOpen}
					aria-owns={DROPDOWN_ELEMENT_ID}
					onChange={onQueryChange}
					onKeyDown={onInputKeydown}
					value={displayQuery}
				/>
				{isDropdownOpen && (
					<div
						className="modern-search__suggestions-container"
						role="listbox"
						aria-label="suggestions"
						id={DROPDOWN_ELEMENT_ID}
						onScroll={(e) => e.stopPropagation()}
					>
						{isEmptyState && <DropdownHistory className="modern-search__suggestions-scroll-area" onOpenHistoryItem={handleOpenHistoryItem} />}
						{!isEmptyState && <DropdownSuggestions className="modern-search__suggestions-scroll-area" query={trimmedQuery} closeSuggestions={closeDropdown} />}
						<DropdownFooter
							className="modern-search__footer"
							viewAllLabel={"Open search page"}
							viewAllLink={allResultsLink}
							onOpenAllResults={handleOpenAllResult}
							onOpenAdvancedSearch={handleOpenAdvancedSearch}
						/>
					</div>
				)}
			</FocusZone>
			{isDropdownOpen && (
				<TooltipHost content="Commit search query">
					<button
						className="modern-search__input-action"
						data-test-action="commit"
						onClick={(event) => onCommitSearch(event.ctrlKey)}
						aria-label="Commit search query"
					>
						<FontIcon iconName="Forward" />
					</button>
				</TooltipHost>
			)}
		</StyledSearchWidget>
	);
};

interface SearchWidgetStyleProps {
	$isInputFocused?: boolean;
	$isSearchBoxStretched?: boolean;
}

const StyledSearchWidget = styled.div<SearchWidgetStyleProps>`
	--placeholder-color: rgba(255, 255, 255, 0.85);
	--min-width: 21rem;
	--expanded-width-mobile: calc(100% - 2rem);
	--expanded-width-desktop: calc(50vw + 1rem); /** start at 21 rem and gain 1px for every additional 2px screen width */
	--expanded-width-limit: 40rem;
	--item-side-padding: 1rem;
	--item-vertical-padding: 0.5rem;

	flex: 1 1 auto;
	position: absolute;
	width: ${(props) => (props.$isSearchBoxStretched ? "var(--expanded-width-mobile)" : "var(--min-width)")};
	top: 8px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	height: 32px;
	display: flex;

	@media screen and (min-width: 40rem) {
		width: var(--min-width);
		left: initial;
		right: 6.5rem;
	}

	@media screen and (min-width: 60rem) {
		left: 0;
		right: 0;
		width: ${(props) => (props.$isSearchBoxStretched ? "var(--expanded-width-desktop)" : "var(--min-width)")};
		max-width: var(--expanded-width-limit);
	}

	.modern-search__focus-zone {
		width: 100%;
	}

	.modern-search__search-icon {
		color: ${(props) => (props.$isInputFocused ? tokens.colors.gray160 : "var(--placeholder-color)")};
		position: absolute;
		left: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}

	.modern-search__input-action {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 32px;
		height: 32px;
		font-size: 16px;
		border: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		cursor: pointer;
		color: ${(props) => (props.$isInputFocused ? tokens.colors.gray160 : "var(--placeholder-color)")};

		&:focus,
		&:hover {
			color: ${tokens.colors.gray160};
			background-color: ${tokens.colors.gray30};
		}
	}

	.modern-search__suggestions-container {
		background-color: white;
		position: absolute;
		border-radius: ${tokens.corners.radius4};
		top: calc(32px + 4px);
		width: 100%;
		box-shadow: ${tokens.shadows.depth16};

		display: grid;
		grid-template-rows: 1fr auto;

		max-height: 80vh;
		overflow: hidden;
		animation: fade-in 250ms both;

		@keyframes fade-in {
			0% {
				opacity: 0;
				transform: translate3d(0, -8px, 0);
			}
			100% {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	.modern-search__suggestions-scroll-area {
		overflow-y: auto;
	}
`;

const SearchBoxInput = styled.input`
	/* use 16px prevents safari from zooming into the control */
	font-size: 16px;
	padding-left: 2rem;
	padding-right: 0.5rem;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background-color: #003a6e;
	border: none;
	border-radius: ${tokens.corners.radius4};
	box-shadow: ${tokens.shadows.depth4Inset};
	overflow: hidden;
	text-overflow: ellipsis;

	@media screen and (min-width: 40rem) {
		font-size: 14px;
	}

	&,
	&::placeholder {
		color: var(--placeholder-color);
	}

	&::placeholder {
		font-size: 14px;
	}

	&:focus {
		color: #333;
		background-color: white;
		outline: none;
	}

	&:not(:focus):hover {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.25);
		box-shadow: ${tokens.shadows.depth8};
	}
`;
