import { useQuery } from "@apollo/client";
import { FontIcon } from "@fluentui/react";
import { EntityType, HubEntityType } from "@hits/rest-api-types";
import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { HubSuggestionFragment, SearchSuggestionsInput, SearchSuggestionsOutput, SEARCH_SUGGESTIONS } from "../../../apis/queries/search-suggestions";
import { screenReaderOnly } from "../../../styles/02-tools/screen-reader-only";
import { entityMeta, entityTypes } from "../../../utils/entity-meta";
import { formatTitle } from "../../../utils/format-title";
import { getHubUrl } from "../../../utils/get-hub-url";
import { getReportUrl } from "../../../utils/get-report-url";
import { iconNames } from "../../../utils/icon-names";
import { pluralizeEntity, pluralizeWord } from "../../../utils/pluralize";
import { useDebounce } from "../../../utils/use-debounce";
import { InlineSpinner } from "../../shared/inline-spinner/inline-spinner";
import { LiveAnnouncer } from "../../shared/live-announcer/live-announcer";
import { normalizeHubSuggestions } from "./normalize-hub-suggestions";
import { useSearchBoxAnalytics } from "./use-search-box-analytics";
import { ExceptionMessage } from "./_exception-message";
import { GroupTitle } from "./_group-title";
import { SuggestionItem, SuggestionItemIcon, SuggestionItemText } from "./_suggestion-item";

export interface DropdownSuggestionsProps {
	className?: string;
	query: string;
	closeSuggestions: () => any;
}

interface PhraseSuggestion {
	html: string;
	text: string;
}

interface HubSuggestion {
	/** a description with both content type and title, used for hover tooltip and screenreader */
	id: number;
	tooltip: string;
	displayName: string;
	link: string;
	iconName: string;
	iconTooltip: string;
	entityType: HubEntityType;
	tagName: string;
	score: number;
}

interface ReportSuggestion {
	/** a description with both content type and title, used for hover tooltip and screenreader */
	tooltip: string;
	titleHtml: { __html: string };
	link: string;
	iconName: string;
	iconTooltip: string;
	entityType: EntityType;
	score: number;
}

interface HubDisplaySection {
	entityType: HubEntityType;
	items: HubSuggestion[];
}

interface ReportDisplaySection {
	entityType: EntityType;
	items: ReportSuggestion[];
}

export const QUERY_DEBOUCE_MS = 500;
const MAX_TOTAL_ITEMS = 10;
const MAX_PHRASE_ITEMS = 3;
const MAX_HUB_ITEMS = 3; // make sure hub suggestions don't overwhelm search results

// Hub ranking weights
type MatchType = "exact" | "fuzzy";
const MATCH_TYPE_WEIGHTS: Record<MatchType, number> = {
	exact: 1000,
	fuzzy: 0,
};

/**
 * Why people > products > topics > group?
 * - product > topics because products are easier to recall. Topics require familiarility with HITS taxonomy
 * - group in the end because group names often contain product and topic.
 *   If group is in the end, people can add words to narrow down to groups.
 *   If group is in the beginning, people can never get products or topics to show.
 * - people first is based on a weak hypothesis that users use it most often. Need validation.
 *
 * This must match the ranking in `get-hub-result.ts` for search page result
 */

const HUB_RANKING_WEIGHTS: Record<HubEntityType, number> = {
	[entityTypes.user]: 400,
	[entityTypes.product]: 300,
	[entityTypes.topic]: 200,
	[entityTypes.researchGroup]: 100,
};

// Report ranking weights
const REPORT_RANKING_WEIGHTS: Record<EntityType, number> = {
	[entityTypes.collection]: 400,
	[entityTypes.study]: 300,
	[entityTypes.note]: 200,
};

export const DropdownSuggestions: React.FC<DropdownSuggestionsProps> = (props) => {
	const { trackOpenFindResult } = useSearchBoxAnalytics();
	const debouncedQuery = useDebounce(props.query, "", QUERY_DEBOUCE_MS);
	const { data, loading, error } = useQuery<SearchSuggestionsOutput, SearchSuggestionsInput>(SEARCH_SUGGESTIONS, {
		variables: {
			args: {
				query: debouncedQuery,
			},
		},
		skip: !debouncedQuery.length,
	});
	const isLoading = loading || props.query !== debouncedQuery;

	const [displayPhrases, setDisplayPhrases] = useState<PhraseSuggestion[]>([]);
	const [displayHubSections, setDisplayHubSections] = useState<HubDisplaySection[]>([]);
	const [displayReportSections, setDisplayReportSections] = useState<ReportDisplaySection[]>([]);

	const [isEmpty, setIsEmpty] = useState(false);
	const [resultSummary, setResultSummary] = useState<string | null>(null);

	const mapHubFragmentToDisplaySuggestion = useCallback<(item: HubSuggestionFragment, matchType: MatchType) => HubSuggestion>(
		(item, matchType) => ({
			tooltip: `${entityMeta.get(item.type)!.displayName}: ${item.displayName}`,
			displayName: item.displayName,
			link: getHubUrl(item.type, item.tagName),
			iconName: entityMeta.get(item.type)!.iconName!,
			iconTooltip: entityMeta.get(item.type)!.displayName!,
			entityType: item.type,
			tagName: item.tagName,
			id: item.id,
			score: MATCH_TYPE_WEIGHTS[matchType] + HUB_RANKING_WEIGHTS[item.type],
		}),
		[]
	);

	/**
	 * Build suggestion list. Rank the items:
	 * 1. On top level, Phrases > Hubs > Reports. This is achieved in the render logic itself.
	 * 2. Within Hubs, use predefined weights to rank items
	 * 3. Within Reports, use predefined weights to rank items
	 *
	 * While building the list, make sure
	 * 1. The total number of displayed items across all categories are within the MAX_TOTAL_ITEMS limit
	 * 2. Each categorty is within its own limit: MAX_PHRASE_ITEMS, MAX_HUB_ITEMS
	 * 3. Report result can use up the rest of the slots
	 */
	useEffect(() => {
		let totalRemaining = MAX_TOTAL_ITEMS;
		const resultSummaryFragments: string[] = [];

		// 1 Phrases
		const allPhrases = data?.searchSuggestions.phrases || [];
		const phrases = allPhrases.slice(0, MAX_PHRASE_ITEMS);

		setDisplayPhrases(phrases);
		totalRemaining = totalRemaining - phrases.length;
		phrases.length > 0 && resultSummaryFragments.push(`${phrases.length} ${pluralizeWord("phrase", phrases.length)}`);

		// 2 Hubs

		// 2.1 Classify and score items
		const exactItems: HubSuggestion[] = (data?.searchSuggestions.hubsExact ?? []).map((item) => mapHubFragmentToDisplaySuggestion(item, "exact"));
		const fuzzyItems: HubSuggestion[] = (data?.searchSuggestions.hubsWordStart ?? []).map((item) => mapHubFragmentToDisplaySuggestion(item, "fuzzy"));
		const hubItems = [...exactItems, ...fuzzyItems];
		const normalizedHubItems = normalizeHubSuggestions(hubItems, debouncedQuery);

		// 2.2 Sort results, trim overflow
		const trimmedHubItems = normalizedHubItems.sort((a, b) => b.score - a.score).slice(0, Math.min(MAX_HUB_ITEMS, totalRemaining));

		// 2.3 Allocate the result to the UI. Walk through each sorted item, create new sections as we go.
		const hubSections: HubDisplaySection[] = [];
		trimmedHubItems.forEach((item) => {
			let section = hubSections.find((section) => section.entityType === item.entityType);
			if (!section) {
				section = {
					entityType: item.entityType,
					items: [],
				};

				hubSections.push(section);
			}

			section.items.push(item);
		});

		// 2.4 Render
		setDisplayHubSections(hubSections);
		hubSections.forEach((section) => resultSummaryFragments.push(`${section.items.length} ${pluralizeEntity(section.entityType, section.items.length)}`));
		totalRemaining = Math.max(0, totalRemaining - trimmedHubItems.length);

		// 3 documents

		// 3.1 Classify and score items
		const documentItems: ReportSuggestion[] =
			data?.searchSuggestions.documentSummary.items?.map((result) => ({
				tooltip: `${entityMeta.get(result.entityType)!.displayName!}: ${result.title}`,
				titleHtml: {
					__html: formatTitle(result.highlights[0]?.length ? result.highlights[0] : result.title, {
						isDraft: !result.isPublished,
					}),
				},
				link: getReportUrl(result.entityType, result.id),
				iconName: entityMeta.get(result.entityType)!.iconName!,
				iconTooltip: entityMeta.get(result.entityType)!.displayName!,
				entityType: result.entityType,
				score: REPORT_RANKING_WEIGHTS[result.entityType],
			})) ?? [];

		// 3.2 Sort results, trim overflow
		const trimmedReportItems = documentItems.sort((a, b) => b.score - a.score).slice(0, totalRemaining);

		// 3.3 Allocate the result to the UI. Walk through each sorted item, create new sections as we go.
		const reportSections: ReportDisplaySection[] = [];
		trimmedReportItems.forEach((item) => {
			let section = reportSections.find((section) => section.entityType === item.entityType);
			if (!section) {
				section = {
					entityType: item.entityType,
					items: [],
				};

				reportSections.push(section);
			}

			section.items.push(item);
		});

		// 3.4 Render
		setDisplayReportSections(reportSections);
		reportSections.forEach((section) => resultSummaryFragments.push(`${section.items.length} ${pluralizeEntity(section.entityType, section.items.length)}`));

		// 4. Summarize
		setIsEmpty(phrases.length + hubSections.length + reportSections.length === 0);
		setResultSummary(resultSummaryFragments.length > 0 ? "Suggestions: " + resultSummaryFragments.join(", ") : null);
	}, [data]);

	const handleOpenPhrase = useCallback((event: React.MouseEvent) => {
		trackOpenFindResult({ kind: "suggested-phrase" });
		if (!event.ctrlKey) {
			props.closeSuggestions();
		}
	}, []);

	const handleOpenEntity = useCallback((event: React.MouseEvent, entityType: EntityType) => {
		trackOpenFindResult({ kind: "entity", entityType });
		if (!event.ctrlKey) {
			props.closeSuggestions();
		}
	}, []);

	return (
		<StyledDiv className={props.className}>
			{/* suggested phrases */}
			{!isLoading && displayPhrases.length > 0 && (
				<section>
					<ScreenReaderHeading>Suggested phrases</ScreenReaderHeading>
					{displayPhrases.map((phrase) => (
						<SuggestionItem
							as={RouterLink}
							role="option"
							key={phrase.text}
							to={`/search?q=${encodeURIComponent(phrase.text)}`}
							data-is-focusable={true}
							title={`Search "${phrase.text}"`}
							onClick={handleOpenPhrase}
						>
							<SuggestionItemIcon as={FontIcon} iconName={iconNames.search} aria-label="Phrase: " title="Search" />
							<SuggestionItemText dangerouslySetInnerHTML={{ __html: phrase.html }}></SuggestionItemText>
						</SuggestionItem>
					))}
				</section>
			)}
			{/* suggested hubs */}
			{!isLoading &&
				displayHubSections.length > 0 &&
				displayHubSections.map((section) => (
					<section key={section.entityType}>
						<GroupTitle>
							<span>{pluralizeEntity(section.entityType, section.items.length)}</span>
						</GroupTitle>
						{section.items.map((displayHub) => (
							<SuggestionItem
								as={RouterLink as any}
								role="option"
								key={displayHub.link}
								to={displayHub.link}
								data-is-focusable={true}
								aria-label={displayHub.tooltip}
								title={displayHub.tooltip}
								onClick={(event: React.MouseEvent) => handleOpenEntity(event, displayHub.entityType!)}
							>
								<SuggestionItemIcon as={FontIcon} iconName={displayHub.iconName} aria-label={displayHub.iconTooltip} title={displayHub.iconTooltip} />
								<SuggestionItemText $capitalize={displayHub.entityType === entityTypes.topic}>{displayHub.displayName}</SuggestionItemText>
							</SuggestionItem>
						))}
					</section>
				))}
			{/* suggested reports */}
			{!isLoading &&
				displayReportSections.length > 0 &&
				displayReportSections.map((section) => (
					<section key={section.entityType}>
						<GroupTitle>
							<span>{pluralizeEntity(section.entityType, section.items.length)}</span>
						</GroupTitle>
						{section.items.map((item) => (
							<SuggestionItem
								as={RouterLink as any}
								key={item.link}
								role="option"
								to={item.link}
								data-is-focusable={true}
								title={item.tooltip}
								aria-label={item.tooltip}
								onClick={(event: React.MouseEvent) => handleOpenEntity(event, item.entityType!)}
							>
								<SuggestionItemIcon as={FontIcon} iconName={item.iconName} aria-label={item.iconTooltip} title={item.iconTooltip} />
								<SuggestionItemText dangerouslySetInnerHTML={item.titleHtml}></SuggestionItemText>
							</SuggestionItem>
						))}
					</section>
				))}
			{isLoading && <InlineSpinner />}
			<ExceptionMessage role="status">
				{!isLoading && !error && isEmpty && "Sorry, nothing came up."}
				{!isLoading && error && "Sorry, something went wrong."}
			</ExceptionMessage>
			<LiveAnnouncer>{resultSummary}</LiveAnnouncer>
		</StyledDiv>
	);
};

const ScreenReaderHeading = styled(GroupTitle)`
	${screenReaderOnly}
`;

const StyledDiv = styled.div`
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;
`;
