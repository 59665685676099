import { useEffect } from "react";
import { useUserProfileContext } from "../../contexts/user-profile-context/user-profile-context";
import { appInsights } from "../../utils/app-insights";

export const useInitializeAppInsights = () => {
	const { myProfile } = useUserProfileContext();

	useEffect(() => {
		if (myProfile) appInsights.setAuthenticatedUserContext(myProfile.user.id.toString());
	}, [myProfile]);
};
