import styled from "styled-components";
import { tokens } from "../../../styles/tokens";

export const GroupTitle = styled.h2`
	display: flex;
	font-size: 16px;
	font-weight: 600;
	color: ${tokens.colors.gray160};
	padding: var(--item-vertical-padding) var(--item-side-padding);
`;
