import { gql } from "@apollo/client";
import { NotificationAudience } from "@hits/rest-api-types";

export const ALL_COMMUNICATIONS = gql`
	query AllCommunications {
		communications {
			message
			audience
		}
	}
`;

export interface AllCommunications {
	communications: {
		message: string;
		audience: NotificationAudience;
	}[];
}
