import styled from "styled-components";

export interface SpacerProps {
	$size: 4 | 8 | 16 | 32;
}

export const Spacer = styled.div<SpacerProps>`
	width: 100%;
	height: ${(props) => props.$size || 4}px;
`;
