import styled from "styled-components";
import { maxLineCount } from "../../../styles/02-tools/line-clamp";
import { tokens } from "../../../styles/tokens";
import { RestylableLink } from "../../shared/link/link";

export const SuggestionItemPreview = styled.div`
	font-size: 14px;
	display: flex;
	color: ${tokens.colors.gray160};
`;

export const SuggestionItem = styled(RestylableLink)`
	font-size: 14px;
	line-height: 20px;
	padding: var(--item-vertical-padding) var(--item-side-padding);
	display: flex;
	text-decoration: none;
	color: ${tokens.colors.gray160};
	outline-offset: -1px;

	&:hover {
		color: black;
		text-decoration: none;
		background-color: ${tokens.colors.gray20};
	}
`;

export const SuggestionItemIcon = styled.i`
	user-select: none;
	font-size: 16px;
	margin-right: 0.5rem;
	color: ${tokens.colors.gray130};
`;

// Transient props
// Ref: https://styled-components.com/docs/api#transient-props
export interface SuggestionItemTextProps {
	$capitalize?: boolean;
}

export const SuggestionItemText = styled.div<SuggestionItemTextProps>`
	color: inherit;
	${maxLineCount(3)};
	${(props) => props.$capitalize && `text-transform: capitalize;`}
`;
