import { getFiltersQueryParamString } from "./search-url-utils";
import { Filters, Sort } from "./use-managed-url";

export interface BuildSearchUrlOptions {
	query: string;
	spellCheck?: boolean;
	filters?: Partial<Filters>;
	sort?: Sort;
}
export const buildSearchUrl = (options: BuildSearchUrlOptions) => {
	let urlSearchParams = new URLSearchParams("top=10");
	urlSearchParams.set("q", options.query);
	// `undefined` should not trigger this logic
	if (options.spellCheck === false) {
		urlSearchParams.set("spellCheck", "false");
	}
	if (options.filters) {
		urlSearchParams = getFiltersQueryParamString(urlSearchParams, options.filters);
	}

	if (options.sort) {
		urlSearchParams.set("sort", options.sort);
	}

	const url = `/search?${urlSearchParams.toString()}`;

	return url;
};
