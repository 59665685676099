import type { AccountInfo, AuthenticationResult, AuthError, Configuration, RedirectRequest } from "@azure/msal-browser";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { CLEAR_USER_CACHE } from "../apis/mutations/clear-user-cache";
import { apolloService } from "./apollo-service";
import { appInsights } from "./app-insights";
import { env } from "./env";
import { referrer } from "./referrer";

const GRAPH_API_RESOURCE_ID = "https://graph.microsoft.com";
const LOGIN_AUTHORITY = `https://login.microsoftonline.com/${env.aadTenantId}/`;

const hitsAuthRequest: RedirectRequest = {
	scopes: [`${env.hitsApiResourceId}/.default`],
};

const graphAuthRequest: RedirectRequest = {
	scopes: [`${GRAPH_API_RESOURCE_ID}/.default`],
};

const msalConfig: Configuration = {
	auth: {
		authority: LOGIN_AUTHORITY,
		clientId: env.aadClientId,
		redirectUri: location.origin,
	},
};

interface AuthServiceProps {
	msalConfig: Configuration;
	hitsAuthRequest: RedirectRequest;
	graphAuthRequest: RedirectRequest;
}

export class AuthService {
	private msalInstance: PublicClientApplication;
	private resolveUsername!: (username: string) => void;
	private usernameAsync = new Promise<string>((resolve) => (this.resolveUsername = resolve));
	private isPageReloadRequested = false;

	constructor({ msalConfig }: AuthServiceProps) {
		this.msalInstance = new PublicClientApplication(msalConfig);
	}

	async signOut() {
		try {
			// The following msal signout call will remove the auth token
			// thus preventing cache clearing.
			// We must use `await` to ensure token exists until cache is cleared
			await apolloService.client.mutate({ mutation: CLEAR_USER_CACHE });
		} finally {
			// Force signout even if clear cache fails
			this.msalInstance.logoutRedirect();
		}
	}

	async getAccount() {
		const graphResult = await this.getToken(await this.usernameAsync, graphAuthRequest);
		return graphResult?.account;
	}

	async getHitsApiToken(): Promise<string> {
		return this.getHitsApiAuth()
			.then((apiAuth) => apiAuth.accessToken)
			.catch(() => "");
	}

	async getMicrosoftGraphToken(): Promise<string> {
		return this.getMicrosoftGraphAuth()
			.then((graphAuth) => graphAuth.accessToken)
			.catch(() => "");
	}

	async getHitsApiAuth(): Promise<AuthenticationResult> {
		const response = await this.getToken(await this.usernameAsync, hitsAuthRequest);
		if (!response) {
			const error = new Error("Authentication failed");
			appInsights.trackException({ exception: error });
			throw error;
		}
		return response;
	}

	async getMicrosoftGraphAuth(): Promise<AuthenticationResult> {
		const response = await this.getToken(await this.usernameAsync, graphAuthRequest);
		if (!response) {
			const error = new Error("Authentication failed");
			appInsights.trackException({ exception: error });
			throw error;
		}
		return response;
	}

	async refreshMicrosoftGraphAuth() {
		return this.msalInstance.acquireTokenSilent({ ...graphAuthRequest, forceRefresh: true });
	}
	async refreshHitsApiAuth() {
		return this.msalInstance.acquireTokenSilent({ ...hitsAuthRequest, forceRefresh: true });
	}

	async autoSignIn(): Promise<AccountInfo | null | undefined> {
		try {
			const redirectResult = await this.msalInstance.handleRedirectPromise();
			this.trackAuthEvent("start");

			if (redirectResult?.account) {
				console.log(`[auth-service] Found existing account from redirect`);
				const username = redirectResult!.account.username;
				const graphResult = await this.getToken(username, graphAuthRequest);
				this.resolveUsername(username);
				this.trackAuthEvent("success-after-redirect", graphResult?.account);
				return graphResult?.account;
			} else {
				console.log(`[auth-service] Found no account from redirect, look into local state`);
				/**
				 * See here for more info on account retrieval:
				 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
				 */
				const currentAccounts = this.msalInstance.getAllAccounts();
				if (currentAccounts === null || !currentAccounts.length) {
					console.log(`[auth-service] No account found in local state. Redirect user to sign-in with graph`);
					// Remember the initial referrer before redirect wipes it out
					referrer.set(document.referrer);
					this.trackAuthEvent("redirect");
					this.msalInstance.acquireTokenRedirect(graphAuthRequest);
				} else if (currentAccounts.length > 1) {
					console.log(`[auth-service] Multiple accounts found in local state. Prompt user with error message`);
					this.handleMultiAccountSignIn();
				} else if (currentAccounts.length === 1) {
					console.log(`[auth-service] Single accounts found in local state.`);
					const username = currentAccounts[0].username;
					this.trackAuthEvent("success-without-redirect", currentAccounts[0]);
					this.resolveUsername(username);
				}
			}
		} catch (error: any) {
			console.error(error);
			appInsights.trackException({ exception: error });
			this.handleFatalError(error);
		}
	}

	private trackAuthEvent(step: "start" | "success-after-redirect" | "success-without-redirect" | "redirect", account?: AccountInfo | null) {
		const webPackageVersion = process.env.WEB_PACKAGE_VERSION;
		const commitHash = process.env.COMMIT_HASH;
		appInsights.trackEvent({ name: "auth" }, { step, account, webPackageVersion, commitHash });
	}

	private async getToken(username: string, request: RedirectRequest) {
		request.account = this.msalInstance.getAccountByUsername(username)!;

		/**
		 * See here for more info on account retrieval:
		 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
		 */
		const authResult = await this.msalInstance.acquireTokenSilent(request).catch((error) => {
			console.log("[auth-service] Silent token acquisition fails.");
			if (error instanceof InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				console.log("[auth-service] Will acquiring token using redirect");
				this.msalInstance.acquireTokenRedirect(request);
				return null;
			} else {
				console.error("[auth-service] Fatal error in silent token acquisition. Prompt user with error message");
				this.handleFatalError(error);
				return null;
			}
		});

		return authResult;
	}

	private handleFatalError(err?: AuthError) {
		if (this.isPageReloadRequested) return;

		const errorCode = err?.errorCode ?? "Unknown";
		const errorMessage = err?.errorMessage ?? "Unknown";

		const fatalErrorNotice = `
Sorry, something went wrong during sign-in.

1. If you are in the Microsoft organization, please make sure your device is enrolled to be managed by Microsoft.
2. If you are a guest from a different organization, please make sure your account has been approved for accessing HITS.
3. Make sure you are using the latest version of Microsoft Edge.
4. Proceed to sign out and try again.

For additional help, email HITS911@microsoft.com with the following details:

Code: ${errorCode}
Message: ${errorMessage}`.trim();

		// To rule out issues caused by browser navigation, we wait for extra time before prompting user the error message.
		// This allows all outstanding navigation to settle.
		console.log("[auth-service] fatal error detected. Will prompt user after all navigation is settled.");
		setTimeout(() => {
			appInsights.trackException({ exception: { name: errorCode, message: errorMessage } });

			const acceptToSignOut = window.confirm(fatalErrorNotice);
			if (acceptToSignOut) {
				this.isPageReloadRequested = true;
				this.signOut();
			}
		}, 1000);
	}

	private handleMultiAccountSignIn() {
		const multiAccountNotice = `You have signed in with multiple accounts. This is not supported by HITS.
		
Proceed to sign out the accounts that are not associated with HITS and try again.

If the issue persists, please contact HITS admins.`;

		appInsights.trackException({ exception: { name: "Multi-user sign-in", message: "Multiple account sign-in are not supported" } });
		const acceptToSignOut = window.confirm(multiAccountNotice);

		if (acceptToSignOut) {
			this.signOut();
		}
	}
}

/** This is a singleton. The app can have one and only one auth service */
export const authService = new AuthService({ msalConfig, hitsAuthRequest, graphAuthRequest });
