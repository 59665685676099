import { gql } from "@apollo/client";
import { EntityType, HubEntityType } from "@hits/rest-api-types";

const HUB_SUGGESTION_FRAGMENT = gql`
	fragment HubSuggestionFragment on Hub {
		id
		tagName
		tagReferenceId
		displayName
		type
	}
`;

export interface HubSuggestionFragment {
	id: number;
	tagName: string;
	tagReferenceId: number;
	displayName: string;
	type: HubEntityType;
}

export interface DocumentSuggestionItem {
	entityType: EntityType;
	highlights: string[];
	id: number;
	isPublished: boolean;
	title: string;
}

export const SEARCH_SUGGESTIONS = gql`
	query SearchSuggestions($args: SearchSuggestionsArgs!) {
		searchSuggestions(args: $args) {
			phrases {
				html
				text
			}
			hubsExact {
				...HubSuggestionFragment
			}
			hubsWordStart {
				...HubSuggestionFragment
			}
			documentSummary {
				count
				items {
					entityType
					highlights
					id
					isPublished
					title
				}
			}
		}
	}
	${HUB_SUGGESTION_FRAGMENT}
`;

export interface SearchSuggestionsOutput {
	searchSuggestions: {
		phrases: {
			html: string;
			text: string;
		}[];
		hubsExact: HubSuggestionFragment[];
		hubsWordStart: HubSuggestionFragment[];
		documentSummary: {
			count: number;
			items: DocumentSuggestionItem[] | null;
		};
	};
}

export interface SearchSuggestionsInput {
	args: {
		query: string;
	};
}
