export const env = {
	/** Client ID for authenticating with AAD */
	aadClientId: import.meta.env.VITE_AAD_CLIENT_ID,
	/** Tenant ID for AAD */
	aadTenantId: import.meta.env.VITE_AAD_TENANT_ID,
	/** Application insights instrumentation key */
	aiInstrumentationKey: import.meta.env.VITE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
	/** App display name in the header */
	appTitle: import.meta.env.VITE_APP_TITLE,
	/** The base url for links that take user from modern frontend to the dotnet frontend */
	dotnetFrontendBaseUrl: import.meta.env.VITE_DOTNET_FRONTEND_BASE_URL,
	/** Azure Application Gateway for routing and load balancing */
	hitsApiGatewayUrl: import.meta.env.VITE_HITS_API_GATEWAY_URL,
	/** Resource ID for authenticating with AAD */
	hitsApiResourceId: import.meta.env.VITE_HITS_API_RESOURCE_ID,
	/** URL for HITS graphql api server */
	hitsGraphqlEndpoint: import.meta.env.VITE_HITS_GRAPHQL_ENDPOINT,
	/** The HITS REST API endpoint consumed by graphql server.
	 * @deprecated graphql server will get its target from its own environment
	 */
	hitsGraphqlRestTarget: import.meta.env.VITE_HITS_GRAPHQL_REST_TARGET,
	/** API endpoint for the web socket service that handles multi-user editing */
	hitsRealtimeEndpoint: import.meta.env.VITE_HITS_REALTIME_ENDPOINT,
	/** Azure function endpoint for Save API */
	hitsSaveApiBaseUrl: import.meta.env.VITE_HITS_SAVE_API_BASE_URL,
	/** For embedded Office file */
	hitsSharePointApiBaseUrl: import.meta.env.VITE_SHARE_POINT_API_BASE_URL,
	/** AppId for Office Customer Voice service, generated during onboarding: https://www.owiki.ms/wiki/OCV/Getting_Started_With_OCV */
	ocvAppId: parseInt(import.meta.env.VITE_OCV_APP_ID),
	/** API endpoint for Office Customer Voice service */
	petroApiBaseUrl: import.meta.env.VITE_PETRO_API_BASE_URL,
};
