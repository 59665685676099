export interface FormatTitleConfig {
	fallbackTitle?: string;
	isDraft?: boolean;
	draftPrefix?: string;
}

const DEFAULT_CONFIG: FormatTitleConfig = {
	fallbackTitle: "Untitled",
	draftPrefix: "(Draft) ", // notice the space
	isDraft: false,
};

export function formatTitle(title?: string | null, config?: FormatTitleConfig) {
	const { fallbackTitle, draftPrefix, isDraft } = { ...DEFAULT_CONFIG, ...config };

	const partTitle = title?.trim().length ? title : fallbackTitle;
	const partPrefix = isDraft ? draftPrefix : "";

	return `${partPrefix}${partTitle}`;
}
