import React, { useEffect, useState } from "react";

export interface DelayedProps extends React.PropsWithChildren {
	/**
	 * @default 0
	 */
	timeout?: number;
}

export const Delayed: React.FC<DelayedProps> = ({ timeout = 0, children }) => {
	const [isDelayedRenderReady, setIsDelayedRenderReady] = useState(false);

	// Ensure the live region is rendered first and the message appears next. Screenreaders such as NVDA and JAWS
	// will ignore the initial content in live region and only announce subsequent changes
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsDelayedRenderReady(true);
		}, timeout);

		return () => clearTimeout(timeoutId);
	}, [timeout, children]);

	return <>{isDelayedRenderReady ? children : null}</>;
};
