export interface RequestConfig {
	accessToken?: string;
	baseUrl?: string;
}

export async function get(path: string, config?: RequestConfig) {
	const fullPath = config?.baseUrl ? `${config.baseUrl}${path}` : path;
	const headers = {
		...(config?.accessToken && { Authorization: `Bearer ${config.accessToken}` }),
	};

	const response = await fetch(fullPath, {
		headers,
	});

	return response;
}

export async function post(path: string, body?: any, config?: RequestConfig) {
	const fullPath = config?.baseUrl ? `${config.baseUrl}${path}` : path;
	const headers = {
		"Content-type": "application/json",
		...(config?.accessToken && { Authorization: `Bearer ${config.accessToken}` }),
	};

	const response = await fetch(fullPath, {
		headers,
		method: "POST",
		...(body && { body: JSON.stringify(body) }),
	});

	return response;
}
