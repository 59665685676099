import { useEffect } from "react";

/** execute a function when the focus leaves a DOM tree  */
export function useOnFocusOut<T extends HTMLElement>(ref: React.RefObject<T>, handler: (e: FocusEvent) => void, ignoreNull = true) {
	useEffect(() => {
		const listener = (event: FocusEvent) => {
			const newFocusTarget = event.relatedTarget as HTMLElement | null;
			if (ignoreNull && !newFocusTarget) {
				return;
			}

			if (!ref.current || ref.current.contains(newFocusTarget)) {
				return;
			}

			handler(event);
		};

		ref.current?.addEventListener("focusout", listener);

		return () => {
			ref.current?.removeEventListener("focusout", listener);
		};
	}, [ref, handler]);
}
