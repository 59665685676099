import type { AccountInfo } from "@azure/msal-browser";
import React, { createContext, useContext, useEffect, useState } from "react";
import { authService } from "../utils/auth-service";

export type AuthState = "signed-out" | "signing-in" | "signed-in";

export interface AuthContextType {
	authState: AuthState;
	account: AccountInfo | null;
	getHitsApiToken: () => Promise<string>;
	getMicrosoftGraphToken: () => Promise<string>;
}

export const AuthContext = createContext<AuthContextType>({} as any);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const isInIframe = self !== top; // The script is loaded in iframe when MSAL handles auth redirect
	if (isInIframe) return null; // Don't render anything or use any hook when in iframe

	const [authState, setAuthState] = useState<AuthState>("signed-out");
	const [account, setAccount] = useState<AccountInfo | null>(null);

	useEffect(() => {
		setAuthState("signing-in");

		/** We put signIn into React Context (as opposed to index.ts) because we want to show a styled sign-in progress indicator that improves perceived performance */
		authService.getAccount().then((account) => {
			if (account) {
				setAccount(account);
				setAuthState("signed-in");
			}
		});
	}, []);

	const contextObject = {
		authState,
		account,
		getHitsApiToken: () => authService.getHitsApiToken(),
		getMicrosoftGraphToken: () => authService.getMicrosoftGraphToken(),
	};

	return <AuthContext.Provider value={contextObject}>{props.children}</AuthContext.Provider>;
};

export function useAuthContext() {
	return useContext(AuthContext);
}
