import { gql } from "@apollo/client";
import { PageViewEntityType } from "@hits/rest-api-types";

export const SEARCHBOX_HISTORY_ITEMS = gql`
	query PageViews($args: PageViewsArgs!) {
		pageViews(args: $args) {
			entityId
			entityType
			timestamp
			relatedEntity {
				title
				isConfidential
				isDeleted
				isPublished
			}
		}
	}
`;

export interface SearchboxHistoryItemsOutput {
	pageViews: {
		entityId: number;
		entityType: PageViewEntityType;
		timestamp: string;
		relatedEntity: {
			title: string;
			isConfidential: boolean;
			isDeleted: boolean;
			isPublished: boolean;
		} | null;
	}[];
}

export interface SearchboxHistoryItemsInput {
	args: {
		userId: number;
		limit: number;
	};
}
