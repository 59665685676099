import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useGraphApi } from "../apis/use-graph-api";
import { RequestConfig, get } from "../utils/request";

export interface UserPhotosContextType {
	getPhotoUrl: (identifier?: string) => string | null | undefined;
	/**
	 * @param identifiers use Graph object id or principal name (alia-AT-microsoft.com).
	 * Prefer Graph object for tenant portability
	 */
	requestPhotos: (identifiers: string | string[]) => void;
	myPhotoUrl?: string | null;
}

const UserPhotosContext = createContext<UserPhotosContextType>({
	getPhotoUrl: () => undefined,
	requestPhotos: () => {},
});

const imageUrlPendingPlaceholder = "PENDING";

const photosMap = new Map<string, string | null | undefined>();

let resolveRequestConfig: (value: RequestConfig) => void;
let isRequestConfigResolved = false;
const requestConfigAsync = new Promise<RequestConfig>((resolve) => (resolveRequestConfig = resolve));

export const UserPhotosContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const { requestConfig } = useGraphApi();
	const [photosChanged, setPhotosChanged] = useState(0);
	const [myPhotoUrl, setMyPhotoUrl] = useState<string | null>();

	useEffect(() => {
		if (isRequestConfigResolved) return;
		if (!requestConfig) return;

		resolveRequestConfig(requestConfig);
		isRequestConfigResolved = true;
	}, [requestConfig]);

	// get photo
	useEffect(() => {
		if (!requestConfig) return;

		get(`/me/photos/96x96/$value`, requestConfig)
			.then((response) => {
				if (response.ok) {
					return response.blob();
				} else {
					throw Error();
				}
			})
			.then((rawData) => {
				const blobUrl = URL.createObjectURL(rawData);
				setMyPhotoUrl(blobUrl);
			})
			.catch((error) => {
				console.error("Cannot get my photo", error);
				setMyPhotoUrl(null);
			});
	}, [requestConfig]);

	const getPhotoUrl = useCallback(
		(identifier?: string) => {
			if (!identifier) return;

			const result = photosMap.get(identifier);

			if (!result) return;
			if (result === imageUrlPendingPlaceholder) return;

			return result;
		},
		[photosChanged]
	);

	const requestPhotoByIdentifier = useCallback(async (identifier: string) => {
		const existingEntry = photosMap.get(identifier);

		if (existingEntry !== undefined) {
			return; // already cached
		}

		if (existingEntry === imageUrlPendingPlaceholder) {
			return; // requesting in progress
		}

		// Set the flag before starting the request
		photosMap.set(identifier, imageUrlPendingPlaceholder);

		const requestConfig = await requestConfigAsync;
		try {
			const response = await get(`/users/${identifier}/photos/96x96/$value`, requestConfig);
			if (response.ok) {
				const rawData = await response.blob();
				const blobUrl = URL.createObjectURL(rawData);

				photosMap.set(identifier, blobUrl);
			} else {
				throw Error();
			}
		} catch (e) {
			photosMap.set(identifier, null);
		}
	}, []);

	const requestPhotos = useCallback(async (identifiers: string[] | string) => {
		const identifierArray = Array.isArray(identifiers) ? identifiers : [identifiers];
		const qualifiedIdentifiers = identifierArray.filter((identifier) => identifier && identifier.length > 0) as string[];
		const deduplicatedIdentifiers = [...new Set(qualifiedIdentifiers)];

		await Promise.all(deduplicatedIdentifiers.map((identifier) => requestPhotoByIdentifier(identifier)));

		setPhotosChanged((prev) => 1 - prev);
	}, []);

	const contextObject = {
		getPhotoUrl,
		requestPhotos,
		myPhotoUrl,
	};

	return <UserPhotosContext.Provider value={contextObject}>{props.children}</UserPhotosContext.Provider>;
};

export function useUserPhotosContext() {
	return useContext(UserPhotosContext);
}
