import { EntityType } from "@hits/rest-api-types";
import { entityMeta } from "./entity-meta";

// currently, HITS only support displaying English or US locale.
const RULES = new Intl.PluralRules("en-US");

export function pluralizeEntity(entityType: EntityType, count?: number): string | undefined {
	if (count === undefined) return undefined;
	if (typeof count !== "number") return undefined;

	switch (RULES.select(count)) {
		case "one":
			return entityMeta.get(entityType)?.displayName;
		default:
			return entityMeta.get(entityType)?.displayNamePlural;
	}
}

export function pluralizeWord(baseWord: string, count = 0): string {
	const dictionaryEntry = DICTIONARY.get(baseWord);

	if (dictionaryEntry) {
		return dictionaryEntry[RULES.select(count)];
	} else {
		switch (RULES.select(count)) {
			case "one":
				return baseWord;
			default:
				return baseWord + "s";
		}
	}
}

export type DictionaryEntry = Record<Intl.LDMLPluralRule, string>;

/**
 * This will be replaced by machine generated resource file, once hits support multi-language
 */
const DICTIONARY = new Map<string, DictionaryEntry>();
