export const iconNames = {
	add: "CalculatorAddition", // Don't use "Add" - it's blurry at 16x16
	attachment: "Attach",
	close: "Cancel",
	collection: "Library",
	contentType: "Page",
	hub: "HomeGroup",
	insight: "Lightbulb",
	method: "TestBeaker",
	product: "Product",
	publishDate: "Calendar",
	recommendation: "Like",
	researcher: "Contact",
	researchGroup: "Group",
	resource: "PhotoVideoMedia",
	search: "Search",
	section: "Page",
	study: "Articles",
	topic: "Chat",
	user: "Contact",
	webPage: "Globe",
};
