import { useCallback } from "react";

export interface HeadlessFileInputConfig {
	onChange: (files: File[]) => void;
	/**
	 * ref to https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
	 */
	accept?: string;
}

export function useHeadlessFileInput(config: HeadlessFileInputConfig) {
	const pickFiles = useCallback(() => {
		// prepare a file input on-the-fly
		const inputElement = document.createElement("input");
		inputElement.type = "file";
		inputElement.style.display = "none";

		if (config?.accept) {
			inputElement.accept = config.accept;
		}

		inputElement.addEventListener("change", () => handleFileChange(inputElement));

		document.body.appendChild(inputElement);

		// trigger the input event
		inputElement.click();
	}, []);

	const handleFileChange = useCallback((inputElement: HTMLInputElement) => {
		if (inputElement?.files) {
			config.onChange([...inputElement.files]);
		}

		// clean up after each pick
		inputElement.remove();
	}, []);

	return {
		pickFiles,
	};
}
