import { HitsRole as HitsRoleType } from "@hits/rest-api-types";

export interface HitsRoles {
	none: HitsRoleType.None;
	guestUser: HitsRoleType.GuestUser;
	user: HitsRoleType.User;
	pilotUser: HitsRoleType.PilotUser;
	researcher: HitsRoleType.Researcher;
	leadResearcher: HitsRoleType.LeadResearcher;
	editor: HitsRoleType.Editor;
	admin: HitsRoleType.Admin;
}

export const hitsRoles: HitsRoles = {
	none: 0,
	guestUser: 1,
	user: 2,
	pilotUser: 4,
	researcher: 8,
	leadResearcher: 16,
	editor: 32,
	admin: 64,
};

export interface HitsRoleMeta {
	displayName: string;
}

export const roleMeta = new Map<HitsRoleType, HitsRoleMeta>([
	[
		hitsRoles.none,
		{
			displayName: "None",
		},
	],
	[
		hitsRoles.guestUser,
		{
			displayName: "Guest",
		},
	],
	[
		hitsRoles.user,
		{
			displayName: "User",
		},
	],
	[
		hitsRoles.pilotUser,
		{
			displayName: "Pilot user",
		},
	],
	[
		hitsRoles.researcher,
		{
			displayName: "Researcher",
		},
	],
	[
		hitsRoles.leadResearcher,
		{
			displayName: "Lead researcher",
		},
	],
	[
		hitsRoles.editor,
		{
			displayName: "Editor",
		},
	],
	[
		hitsRoles.admin,
		{
			displayName: "Admin",
		},
	],
]);
