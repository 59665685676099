import { getTheme } from "@fluentui/react";
import React from "react";
import { DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

const defaultTheme: DefaultTheme = {
	fluentUi: getTheme(),
};

export const ThemeContextProvider: React.FC<React.PropsWithChildren> = (props) => (
	<StyledComponentsThemeProvider theme={defaultTheme}>{props.children}</StyledComponentsThemeProvider>
);
