import { gql } from "@apollo/client";
import { UserVisitEntityType } from "@hits/rest-api-types";

export const ADD_PAGE_VIEW = gql`
	mutation AddPageView($args: AddPageViewArgs!) {
		addPageView(args: $args) {
			entityId
			entityType
		}
	}
`;

export interface AddPageViewInput {
	args: {
		entityId: number;
		entityType: UserVisitEntityType;
		url: string;
		referrerUrl: string;
		sessionId: string;
	};
}

export interface AddPageViewOutput {
	addPageView: {
		entityId: number;
		entityType: UserVisitEntityType;
	};
}
