import { gql } from "@apollo/client";
import { EntityType } from "@hits/rest-api-types";

export const MY_CONTRIBUTION_DRAFTS = gql`
	query MyContributionDrafts {
		myContent {
			draftNotes {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				family {
					id
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
			draftStudies {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				family {
					id
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
			draftCollections {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				family {
					id
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
		}
	}
`;

export interface MyContributionDrafts {
	myContent: {
		draftNotes: MyContributionDraftReport[];
		draftStudies: MyContributionDraftReport[];
		draftCollections: MyContributionDraftReport[];
	};
}

export interface MyContributionDraftReport {
	id: number;
	entityType: EntityType.Study | EntityType.Collection;
	title: string;
	createdOn: string;
	updateOn: string;
	family: {
		id: number;
		pointOfContact: {
			alias: string;
			directoryObjectId: string;
			displayName: string;
		} | null;
		researchers:
			| {
					alias: string;
					directoryObjectId: string;
					displayName: string;
			  }[]
			| null;
	};
	viewCount: number;
}
