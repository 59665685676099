import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { CustomerServiceDialogProps, TopLevelType } from "../components/app-header/customer-service-dialog/customer-service-dialog";
import { bifrost } from "../utils/bifrost";

export type OnCloseCallback = () => any;

export interface CustomerServiceDialogContextType extends CustomerServiceDialogProps {
	open: (type?: TopLevelType, initialContent?: string, onClose?: OnCloseCallback) => void;
	isOpen: boolean;
}

const CustomerServiceDialogContext = createContext<CustomerServiceDialogContextType>({
	open: () => {},
	isOpen: false,
});

export const CustomerServiceDialogContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [topLevelType, setTopLevelType] = useState<TopLevelType>();
	const [initialContent, setInitialContent] = useState("");

	const open = useCallback((type = TopLevelType.SupportRequest, initialContent = "") => {
		setTopLevelType(type);
		setIsOpen(true);
		setInitialContent(initialContent);
	}, []);

	const onClose = useCallback(() => {
		setTopLevelType(undefined);
		setIsOpen(false);
		setInitialContent("");
	}, []);

	useEffect(
		() =>
			bifrost.events.on("open-help-dialog", (data) => {
				// When dialog opened from classic side, we must manually release focus trap
				// This workaround is relevant only if we want to open a modern dialog on top of a classic dialog
				document.querySelectorAll("focus-trap-element").forEach((elem: any) => {
					if (!elem.allowFocusoutOnce) {
						console.warn("allowFocusoutOnce method is missing from <focus-trap-element>");
					} else {
						elem.allowFocusoutOnce();
					}
				});
				open(TopLevelType.SupportRequest, data.initialContent);
			}),
		[]
	);

	const contextValue: CustomerServiceDialogContextType = { open, onClose, isOpen, topLevelType, initialContent };
	return <CustomerServiceDialogContext.Provider value={contextValue}>{props.children}</CustomerServiceDialogContext.Provider>;
};

export function useCustomerServiceDialogContext() {
	return useContext(CustomerServiceDialogContext);
}
