/**
 * Decompose an integer into an array of powers of two.
 * E.g. 72 => [8, 64]
 * @deprecated avoid computation-heavy conversion. Use bitwise operator directly
 */
export function getPowersOfTwoArray(total: number) {
	// This will go 1, 2, 4, 8, etc. In binary, it will be 1, 10, 100, 1000, etc.
	let currentMask = 1;
	const presentPowers = [];
	while (currentMask <= total) {
		if (currentMask & total) presentPowers.push(currentMask);
		// Shift left, pad 0 to the right, so 1 => 10, 10 => 100, 100 => 1000, etc.
		currentMask <<= 1;
	}
	return presentPowers;
}

export function joinByBitwiseOr(...values: number[]) {
	return values.reduce((result, value) => result | value, 0);
}

export function matchAllBits(sample: number, mask: number) {
	return (sample & mask) === mask;
}

export function matchAnyBit(sample: number, mask: number) {
	return (sample & mask) > 0;
}
