import { DefaultTheme } from "styled-components";

export interface ContextWithFluentTheme {
	theme: DefaultTheme;
}

/**
 * A subset of Fluent UI theme slot colors that HITS design consumes
 * Options can be extends by adotping more slots from the official theming system
 * https://www.aka.ms/themedesigner
 * Our theme is generated with
 * - Primary: #0078d4
 * - Text: #323130
 * - Background: #ffffff
 */
export const palette = {
	neutralLighterAlt: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralLighterAlt,
	/** Menu footer */
	neutralLighter: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralLighter,
	/** Page background, dividers, disabled background */
	neutralLight: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralLight,
	/** Call to action on white background */
	themePrimary: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.themePrimary,
	/** Call to action on off-white background */
	themeDarkAlt: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.themeDarkAlt,
	/** Default text */
	neutralPrimary: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralPrimary,
	/** Secondary text, icons */
	neutralSecondary: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralSecondary,
	/** Non-content elements, dividers, decorations */
	neutralTertiary: (props: ContextWithFluentTheme) => props.theme.fluentUi.palette.neutralTertiary,
};
