import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const URL_PROMOCODE_SEARCH_KEY = "promo";
export const STORAGE_PROMOCODE_KEY = "promo";

/** Initialize PromoCode from sessionStorage. This runs only once per app */
let sessionPromoCode: string | null = sessionStorage.getItem(STORAGE_PROMOCODE_KEY);

export function setLatestPromoCode(value: string | null) {
	sessionPromoCode = value;
	if (value === null) {
		sessionStorage.removeItem(STORAGE_PROMOCODE_KEY);
	} else {
		sessionStorage.setItem(STORAGE_PROMOCODE_KEY, value);
	}
}

export function getLatestPromoCode() {
	return sessionPromoCode !== null ? sessionPromoCode : sessionStorage.getItem(STORAGE_PROMOCODE_KEY);
}

/**
 * Get PromoCode for current session if exist
 * @return PromoCode
 */
export const useSessionPromoCode = () => {
	const location = useLocation();
	const navigate = useNavigate();

	/** Get PromoCode from the current URL */
	const urlPromoCode = useMemo(() => {
		const mutableSearchParams = new URLSearchParams(location.search.toLocaleLowerCase());
		const promoCode = mutableSearchParams.get(URL_PROMOCODE_SEARCH_KEY);
		return promoCode;
	}, [location.search]);

	/** When PromoCode from URL changes, update the global var (ref) and SessionStorage */
	// Skip when URL doesn't contain PromoCode
	if (urlPromoCode !== null) {
		setLatestPromoCode(urlPromoCode);
	}

	// Clean up URL
	useEffect(() => {
		if (urlPromoCode !== null) {
			const mutableSearchParams = new URLSearchParams(location.search.toLocaleLowerCase());
			mutableSearchParams.delete(URL_PROMOCODE_SEARCH_KEY);

			navigate({ search: mutableSearchParams.toString() }, { replace: true });
		}
	}, [urlPromoCode]);

	const result = getLatestPromoCode();

	return result;
};
