import { createGlobalStyle } from "styled-components";
import { tokens } from "../tokens";

export const ResetStyles = createGlobalStyle`
  h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0rem;
  }

  ol, ul {
    margin: 0;
    padding: 0;
  }

  input,
  button,
  textarea,
  select {
    font-family: inherit;
  }

	html, body, #app {
    @media not print {
      height: 100%;
      overflow: hidden;
    }
  }

  body {
    margin: 0;
    font-family: ${tokens.fonts.system};
    background-color: ${tokens.colors.gray30};
    color: ${tokens.colors.gray160};
  }

  /* hide focus outline for mouse interactions */
  :focus:not(:focus-visible) {
    outline: none;
  }

  *,*::before,*::after {
    box-sizing: border-box;
  }
`;
