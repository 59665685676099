import React, { createContext, useContext } from "react";

export interface TrackerContextProviderType extends React.PropsWithChildren {
	scope?: string;
}

export interface TrackerContextType {
	path: string[];
}

const TrackerContext = createContext<TrackerContextType>({
	path: [],
});

/**
 * The tracker context is designed to be nested.
 * When a tracker context is added, it will append the scope to the existing array of scopes.
 * This constructs a "breadcrumb" like structure to help tracking where an event occurs
 */
export const TrackerContextProvider: React.FC<TrackerContextProviderType> = (props) => {
	const parentValue = useTrackerContext();
	const newPath = [...parentValue.path, ...(props.scope ? [props.scope] : [])];
	const updatedValue = { ...parentValue, path: newPath };
	return <TrackerContext.Provider value={updatedValue}>{props.children}</TrackerContext.Provider>;
};

export function useTrackerContext() {
	return useContext(TrackerContext);
}
