import { gql } from "@apollo/client";
import { EntityType } from "@hits/rest-api-types";

export const MY_CONTRIBUTION_PUBLISHED = gql`
	query MyContributionPublished {
		myContent {
			publishedNotes {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				metric {
					viewCount
					totalUsers
					reportShares
					citedCount
				}
				family {
					id
					publishedDate
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
			publishedStudies {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				metric {
					viewCount
					totalUsers
					reportShares
					citedCount
				}
				family {
					id
					publishedDate
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
			publishedCollections {
				id
				entityType
				title
				createdOn
				updateOn
				viewCount
				metric {
					viewCount
					totalUsers
					reportShares
					citedCount
				}
				family {
					id
					publishedDate
					pointOfContact {
						alias
						directoryObjectId
						displayName
					}
				}
			}
		}
	}
`;

export interface MyContributionPublished {
	myContent: {
		publishedNotes: MyContributionPublishedReport[];
		publishedStudies: MyContributionPublishedReport[];
		publishedCollections: MyContributionPublishedReport[];
	};
}

export interface MyContributionPublishedReport {
	id: number;
	entityType: EntityType.Study | EntityType.Collection | EntityType.Note;
	title: string;
	createdOn: string;
	updateOn: string;
	metric: {
		viewCount: number | null;
		totalUsers: number | null;
		reportShares: number | null;
		citedCount: number | null;
	};
	family: {
		id: number;
		publishedDate: string;
		pointOfContact: {
			alias: string;
			directoryObjectId: string;
			displayName: string;
		} | null;
		researchers:
			| {
					alias: string;
					directoryObjectId: string;
					displayName: string;
			  }[]
			| null;
	};
	viewCount: number;
}
