import { DocumentNode } from "graphql";
import { useCallback } from "react";
import { MyContributionDrafts, MY_CONTRIBUTION_DRAFTS } from "../../apis/queries/my-contribution-drafts";
import { MyContributionPublished, MY_CONTRIBUTION_PUBLISHED } from "../../apis/queries/my-contribution-published";
import { MyContributionStats, MY_CONTRIBUTION_STATS } from "../../apis/queries/my-contribution-stats";
import { apolloService } from "../../utils/apollo-service";

const backups = new Map<DocumentNode, any>();

export function useMockData() {
	const mockNoDrafts = useCallback(() => {
		const existingQuery = apolloService.client.readQuery<MyContributionDrafts>({
			query: MY_CONTRIBUTION_DRAFTS,
		});

		if (existingQuery && !backups.has(MY_CONTRIBUTION_DRAFTS)) {
			backups.set(MY_CONTRIBUTION_DRAFTS, existingQuery);
		}

		apolloService.client.writeQuery<MyContributionDrafts>({
			query: MY_CONTRIBUTION_DRAFTS,
			data: {
				myContent: {
					...existingQuery?.myContent,
					draftCollections: [],
					draftNotes: [],
					draftStudies: [],
				},
			},
		});
	}, []);

	const mockNoPublished = useCallback(() => {
		const existingContentQuery = apolloService.client.readQuery<MyContributionPublished>({
			query: MY_CONTRIBUTION_PUBLISHED,
		});

		if (existingContentQuery && !backups.has(MY_CONTRIBUTION_PUBLISHED)) {
			backups.set(MY_CONTRIBUTION_PUBLISHED, existingContentQuery);
		}

		apolloService.client.writeQuery<MyContributionPublished>({
			query: MY_CONTRIBUTION_PUBLISHED,
			data: {
				myContent: {
					...existingContentQuery?.myContent,
					publishedCollections: [],
					publishedNotes: [],
					publishedStudies: [],
				},
			},
		});

		const existingStatsQuery = apolloService.client.readQuery<MyContributionStats>({
			query: MY_CONTRIBUTION_STATS,
		});

		if (existingStatsQuery && !backups.has(MY_CONTRIBUTION_STATS)) {
			backups.set(MY_CONTRIBUTION_STATS, existingStatsQuery);
		}

		apolloService.client.writeQuery<MyContributionStats>({
			query: MY_CONTRIBUTION_STATS,
			data: {
				myContent: {
					...existingStatsQuery?.myContent,
					publishedCollectionsCount: 0,
					publishedNotesCount: 0,
					publishedStudiesCount: 0,
				},
			},
		});
	}, []);

	const resetAll = useCallback(() => {
		[...backups.entries()].forEach(([query, data]) => {
			apolloService.client.writeQuery({
				query,
				data,
			});
		});

		backups.clear();
	}, []);

	return {
		mockNoDrafts,
		mockNoPublished,
		resetAll,
	};
}
