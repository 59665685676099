import { ProgressIndicator } from "@fluentui/react";
import React from "react";
import styled from "styled-components";
import hitsLogo from "../../assets/images/hits-logo.svg";

export interface AppLoadingIndicatorProps {
	loadingReason?: string;
}

export const AppLoadingIndicator: React.FC<AppLoadingIndicatorProps> = (props) => (
	<StyledDiv className="full-screen-container" aria-live="polite">
		<ProgressIndicator
			description={props.loadingReason}
			styles={{
				itemProgress: {
					padding: 0,
				},
				itemDescription: {
					marginTop: "0.25rem",
					marginLeft: "1rem",
				},
			}}
		/>
		<img src={hitsLogo} aria-hidden="true" className="logo" />
	</StyledDiv>
);

const StyledDiv = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	.logo {
		height: auto;
		width: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-duration: 1500ms;
		animation-name: pulse;
	}

	@keyframes pulse {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0.1;
		}
	}
`;
