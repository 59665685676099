import { ActionButton, Label, MessageBar, MessageBarType, Shimmer, ShimmerElementType } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { convertFileSize } from "../../../utils/convert-file-size";
import { getBlobUrlFromFile } from "../../../utils/get-blob-url-from-file";
import { useHeadlessFileInput } from "../../../utils/use-headless-file-input";

export interface SingleImagePickerProps {
	label?: string;
	image?: File | null;
	/** override local image preview with a remote url */
	remoteImageUrl?: string | null;
	fileSizeLimitMb?: number;
	onImageChanged: (file: File | null) => void;
	disabled?: boolean;
	/** the content that is rendered when disabled */
	disableMessage?: string | JSX.Element | null;
	loading?: boolean;
	guidelinesElement?: JSX.Element | null;
}

export const SingleImagePicker: React.FC<SingleImagePickerProps> = (props) => {
	const [localPreviewUrl, setLocalPreviewUrl] = useState<string | null>(null);
	const [warningMessage, setWarningMessage] = useState<string | null>(null);

	const isPopulated = useMemo(() => {
		const isRemote = props.remoteImageUrl;
		const isLocal = props.image;
		const isRemoving = props.image === null;
		return (isRemote || isLocal) && !isRemoving;
	}, [props.remoteImageUrl, props.image]);

	const { pickFiles } = useHeadlessFileInput({
		accept: ".png, .jpg, .jpeg",
		onChange: (files) => {
			setWarningMessage(null);
			if (files.length > 0) {
				const pickedFileSizeMb = convertFileSize(files[0].size, "MB");
				if (props.fileSizeLimitMb && pickedFileSizeMb > props.fileSizeLimitMb) {
					setWarningMessage(
						`The file you picked (${pickedFileSizeMb.toFixed(2)}MB) has exceeded the recommended size of ${
							props.fileSizeLimitMb
						}MB. Shrink the dimensions or compress the image to create a better user experience.`
					);
				}

				props.onImageChanged(files[0]);
			} else {
				props.onImageChanged(null);
			}
		},
	});

	useEffect(() => {
		// prioritize preivew of local state
		if (props.image !== undefined) {
			if (props.image !== null) {
				getBlobUrlFromFile(props.image).then((url) => setLocalPreviewUrl(url));
			} else {
				setLocalPreviewUrl(null);
			}
		} else {
			setLocalPreviewUrl(props.remoteImageUrl ?? null);
		}
	}, [props.image, props.remoteImageUrl]);

	const handleRemoveImage = useCallback(() => {
		props.onImageChanged(null);
	}, []);

	const isSettledToEnabled = useMemo(() => !props.disabled && !props.loading, [props.disabled, props.loading]);
	const isSettledToDisabled = useMemo(() => props.disabled && !props.loading, [props.disabled, props.loading]);

	return (
		<div>
			{/**
			 * Do not associate the label with a button element
			 * Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#buttons
			 */}
			<Label>{props.label ?? "Image"}</Label>
			{isSettledToEnabled && (
				<ActionsRow>
					<ActionButton iconProps={{ iconName: "Upload" }} onClick={pickFiles}>
						Upload
					</ActionButton>
					{isPopulated && (
						<ActionButton iconProps={{ iconName: "Delete" }} onClick={handleRemoveImage}>
							Remove
						</ActionButton>
					)}
				</ActionsRow>
			)}
			<PreviewGrid>
				{localPreviewUrl && <ImagePreview alt={props.image?.name || "Preview"} src={localPreviewUrl} />}
				{props.loading && <Shimmer data-testid="loading" width={300} shimmerElements={[{ type: ShimmerElementType.line, height: 200 }]}></Shimmer>}
				<div className="aside">
					{warningMessage && (
						<MessageBar aria-live="assertive" messageBarType={MessageBarType.warning}>
							{warningMessage}
						</MessageBar>
					)}
					{isSettledToDisabled && props.disableMessage}
					{isSettledToEnabled && props.guidelinesElement}
				</div>
			</PreviewGrid>
		</div>
	);
};

const PreviewGrid = styled.div`
	display: grid;
	grid-template-columns: auto;
	gap: 16px;

	@media screen and (min-width: 48rem) {
		grid-template-columns: auto 1fr;
	}

	.aside {
		display: grid;
		gap: 16px;
	}
`;

const ActionsRow = styled.div`
	display: flex;
`;

const ImagePreview = styled.img`
	display: block;
	max-width: 320px;
	max-height: 200px;
	object-fit: contain;
`;
