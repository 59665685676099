/**
 * Referrer Manager maintains the correct document.referrer value throughout the lifecycle of the app.
 *
 * Consider limitations with the browser built-in document.referrer:
 *   1. When MSAL redirects to SSO portal and comes back, document.referrer becomes "login.microsoftonline.com" and original referrer would be lost
 *      - Our goal is to recover the original document.referrer before the SSO redirects
 *   2. When SPA router updates the URL, document.referrer will remain unchanged
 *      - Our goal is to update the referrer as user navigates to new client-side "pages" within the app, assuming:
 *        1. Not all URLs are client-side pages
 *        2. Each client-side page has a distinct URL
 *      - It is up to the application to decide what constitutes a "page"
 *   3. When user F5 refresh the page, document.referrer will remain unchnaged
 *      - The goal is to set the current page as the referrer for reloads, so a reloaded page is always referrerd by itself
 *
 * See RFC 17 for design details
 */
export class ReferrerManager {
	private inMemoryValue: string = sessionStorage.getItem("hitsSpaReferrerUrl") ?? document.referrer;
	private getterInvocationCount = 0;

	constructor() {
		console.log(`[referrer] init ${this.inMemoryValue}`);
	}

	/**
	 * Set the referrer url for the future get() calls, call this
	 * 1. Before SSO redirect starts
	 * 2. Each time after a page is viewed, that page's URL should be set here as the referrer for the upcoming page view
	 */
	set(url: string) {
		if (this.getterInvocationCount === 0) {
			console.warn(`[referrer] ${this.inMemoryValue} was set but never used. Did you forget to track a page view?`);
		}
		this.inMemoryValue = url;
		sessionStorage.setItem("hitsSpaReferrerUrl", url);
		this.getterInvocationCount = 0;

		console.log(`[referrer] set ${url}`);
	}

	get(): string {
		this.getterInvocationCount++;
		if (this.getterInvocationCount > 1) {
			console.warn(`[referrer] ${this.inMemoryValue} was consumed ${this.getterInvocationCount} times. Did you track the same page multiple times?`);
		}
		return this.inMemoryValue;
	}
}

export const referrer = new ReferrerManager();
