import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { palette } from "../../../styles/theme";
import { RestylableLink } from "../../shared/link/link";

export interface DropdownFooterProps {
	className?: string;
	viewAllLink: string;
	viewAllLabel: string;
	hideViewAllLink?: boolean;
	onOpenAllResults: () => void;
	onOpenAdvancedSearch: () => void;
}

export const DropdownFooter: React.FC<DropdownFooterProps> = (props) => {
	return (
		<StyledDiv className={props.className}>
			{!props.hideViewAllLink && (
				<AllResultsLink as={Link} to={props.viewAllLink} onClick={props.onOpenAllResults}>
					{props.viewAllLabel}
				</AllResultsLink>
			)}
			<AdvancedSearchLink as={Link} to={`/Search/Advanced`} onClick={props.onOpenAdvancedSearch}>
				Advanced search
			</AdvancedSearchLink>
		</StyledDiv>
	);
};

const StyledDiv = styled.div`
	text-decoration: none;
	font-size: 14px;
	display: flex;
	background-color: ${palette.neutralLighter};

	.all-results {
		padding: var(--item-vertical-padding) var(--item-side-padding);
		margin-right: auto;
	}
	.advanced-search {
		padding: var(--item-vertical-padding) var(--item-side-padding);
		margin-left: auto;
	}
`;

const AllResultsLink = styled(RestylableLink)`
	padding: var(--item-vertical-padding) var(--item-side-padding);
	margin-right: auto;
	color: ${palette.themeDarkAlt};
`;

const AdvancedSearchLink = styled(RestylableLink)`
	padding: var(--item-vertical-padding) var(--item-side-padding);
	margin-left: auto;
	color: ${palette.neutralSecondary};
`;
