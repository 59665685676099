import { User } from "@microsoft/microsoft-graph-types";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useGraphApi } from "../apis/use-graph-api";
import { RequestConfig, get } from "../utils/request";

export interface DirectoryContextType {
	getUserByPrincipalName: (principal?: string) => User | undefined;
	requestUsersByPrincipalName: (principals: string[] | string) => void;
}

export const DirectoryContext = createContext<DirectoryContextType>({
	getUserByPrincipalName: () => undefined,
	requestUsersByPrincipalName: () => {},
});

let resolveRequestConfig: (value: RequestConfig) => void;
let isRequestConfigResolved = false;
const requestConfigAsync = new Promise<RequestConfig>((resolve) => (resolveRequestConfig = resolve));

const usersSet = new Set<User>();

export const DirectoryContextProvider: React.FC<React.PropsWithChildren> = (props) => {
	const { requestConfig } = useGraphApi();
	const [directoryChanged, setDirectoryChanged] = useState(0);

	useEffect(() => {
		if (isRequestConfigResolved) return;
		if (!requestConfig) return;

		resolveRequestConfig(requestConfig);
		isRequestConfigResolved = true;
	}, [requestConfig]);

	const getUserByPrincipalName = useCallback(
		(principal?: string) => {
			if (!principal) return;

			return [...usersSet].find((user) => user.userPrincipalName === principal);
		},
		[directoryChanged]
	);

	const requestUserByPrincipalName = useCallback(async (principal: string) => {
		const existingUser = [...usersSet].find((user) => user.userPrincipalName === principal);
		if (existingUser) {
			return; // already in the map
		}

		const stubUser: User = {
			userPrincipalName: principal,
		};

		usersSet.add(stubUser);

		const requestConfig = await requestConfigAsync;
		try {
			const response = await get(`/users/${principal}`, requestConfig);
			if (response.ok) {
				const user: User = await response.json();

				usersSet.delete(stubUser);
				usersSet.add(user);
			} else {
				throw Error();
			}
		} catch (e) {
			console.log("get user from AAD failed");
			console.log(e);
		}
	}, []);

	const requestUsersByPrincipalName = useCallback(async (identifiers: string[] | string) => {
		const identifierArray = Array.isArray(identifiers) ? identifiers : [identifiers];
		const qualifiedIdentifiers = identifierArray.filter((identifier) => identifier && identifier.length > 0) as string[];
		const deduplicatedIdentifiers = [...new Set(qualifiedIdentifiers)];

		await Promise.all(deduplicatedIdentifiers.map((identifier) => requestUserByPrincipalName(identifier)));

		setDirectoryChanged((prev) => 1 - prev);
	}, []);

	const contextObject = {
		getUserByPrincipalName,
		requestUsersByPrincipalName,
	};

	return <DirectoryContext.Provider value={contextObject}>{props.children}</DirectoryContext.Provider>;
};

export function useDirectoryContext() {
	return useContext(DirectoryContext);
}
