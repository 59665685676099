import { RefObject, useLayoutEffect } from "react";

type OnSizeChangeCallback<T extends Element> = (size: ResizeObserverSize, target: T) => void;

export function useResizeObserver<T extends Element>(ref: RefObject<T>, onSizeChange: OnSizeChangeCallback<T>): void;
export function useResizeObserver<T extends Element>(element: T, onSizeChange: OnSizeChangeCallback<T>): void;
export function useResizeObserver<T extends Element>(refOrElement: T | RefObject<T>, onSizeChange: OnSizeChangeCallback<T>) {
	useLayoutEffect(() => {
		const targetNode = refOrElement instanceof Element ? refOrElement : refOrElement?.current;
		if (!targetNode) return;

		const resizeObserver = new ResizeObserver((entries) => {
			const size = entries?.[0].borderBoxSize?.[0];
			if (size) {
				onSizeChange(size, targetNode as T);
			}
		});

		resizeObserver.observe(targetNode);

		return () => {
			resizeObserver.disconnect();
		};
	}, [onSizeChange]);
}
