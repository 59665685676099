import React from "react";
import ReactDOM from "react-dom";
import { AppRoot } from "./components/app-root/app-root";
import { appInsights } from "./utils/app-insights";
import { authService } from "./utils/auth-service";

performance.mark("AppRoot-Start");
authService.autoSignIn();

function handleReactRendered() {
	/**
	 * Measure from browser starting to parse HTML to React finishing the 1st render.
	 * This is approximately the "blank screen" duration.
	 *
	 * This is affected by
	 * - Network speed (initial download)
	 * - The size of the entry bundle (initial download)
	 * - The computing power of the device
	 *
	 * This is the MVP implementation of perf measurement.
	 * Follow-up work: #32503554
	 */
	const duration = performance.now() - window.scriptingStartTime!;
	// Don't use any hook. This is outside react.
	appInsights.trackMetric({
		name: "react-rendered",
		average: duration,
	});

	console.log(`[perf] react rendered ${(duration / 1000).toFixed(2)}s`);
}

const mountNode = document.getElementById("app");

ReactDOM.render(<AppRoot />, mountNode, handleReactRendered);
