import React from "react";
import styled from "styled-components";
import { screenReaderOnly } from "../../../styles/02-tools/screen-reader-only";
import { Delayed } from "../../../utils/delayed";

export interface LiveAnnounceProps extends React.PropsWithChildren {
	/**
	 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
	 */
	politeness?: "polite" | "assertive";
}

export const LiveAnnouncer: React.FC<LiveAnnounceProps> = (props) => {
	const { politeness = "polite" } = props;

	// Ensure the message appears after a short delay
	// Screenreaders such as NVDA and JAWS will ignore the initial content in live
	// region and only announce subsequent changes

	return (
		<AnnouncementRoot aria-atomic={true} aria-live={politeness}>
			<Delayed timeout={100}>{props.children}</Delayed>
		</AnnouncementRoot>
	);
};

const AnnouncementRoot = styled.div`
	${screenReaderOnly}
`;
