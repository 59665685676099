import { gql } from "@apollo/client";

export const META = gql`
	query Meta {
		meta {
			packageVersion
			nodeVersion
			functionsExtensionVersionRequested
		}
	}
`;

export interface Meta {
	meta: {
		packageVersion: string;
		nodeVersion: string;
		functionsExtensionVersionRequested: string;
	};
}
