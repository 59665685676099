import { Filters } from "./use-managed-url";

export const ALLOWED_FILTERS: (keyof Filters)[] = [
	"entityTypes",
	"topicIds",
	"groupIds",
	"productIds",
	"methodIds",
	"researcherIds",
	"publishDateRange",
	"isPublished",
];

const filterTypes: { [K in keyof Filters]: string } = {
	entityTypes: "array",
	topicIds: "array",
	productIds: "array",
	methodIds: "array",
	groupIds: "array",
	researcherIds: "array",
	publishDateRange: "array",
	isPublished: "boolean",
};

export function getFilterValues<T extends keyof Filters>(searchObject: URLSearchParams, key: T): Filters[T] {
	const raw = searchObject.get(key);
	if (!raw) {
		if (filterTypes[key] === "array") {
			return [] as any;
		} else {
			return undefined as any;
		}
	}
	return JSON.parse(raw);
}

/**
 * @return a copy of the URLSearchParams object in which the given facet will be filtered to the given values
 */
export function replaceFiltersFacetValues<T extends keyof Filters>(searchObject: URLSearchParams, key: T, values: Filters[T]): URLSearchParams {
	const mutableSearchObj = new URLSearchParams(searchObject.toString());
	return setFiltersFacetValues(mutableSearchObj, key, values);
}

function setFiltersFacetValues<T extends keyof Filters>(searchObject: URLSearchParams, key: T, values: Filters[T]): URLSearchParams {
	if ((Array.isArray(values) && !values.length) || values === undefined) searchObject.delete(key);
	else searchObject.set(key, JSON.stringify(values));

	return searchObject;
}

/**
 * Return a copy of the searchParams object with the given filters
 */
export function getFiltersQueryParamString(searchParams: URLSearchParams, filters?: Partial<Filters>): URLSearchParams {
	const mutableSearchObj = new URLSearchParams(searchParams.toString());
	if (!filters) return mutableSearchObj;

	setFiltersFacetValues(mutableSearchObj, "entityTypes", filters.entityTypes ?? []);
	setFiltersFacetValues(mutableSearchObj, "productIds", filters.productIds ?? []);
	setFiltersFacetValues(mutableSearchObj, "topicIds", filters.topicIds ?? []);
	setFiltersFacetValues(mutableSearchObj, "methodIds", filters.methodIds ?? []);
	setFiltersFacetValues(mutableSearchObj, "groupIds", filters.groupIds ?? []);
	setFiltersFacetValues(mutableSearchObj, "researcherIds", filters.researcherIds ?? []);
	setFiltersFacetValues(mutableSearchObj, "publishDateRange", filters.publishDateRange ?? []);
	setFiltersFacetValues(mutableSearchObj, "publishDateRange", filters.publishDateRange ?? []);
	setFiltersFacetValues(mutableSearchObj, "isPublished", filters.isPublished);

	return mutableSearchObj;
}

export function getFiltersFromSearchObject(searchObject: URLSearchParams): Filters {
	return Object.fromEntries(
		ALLOWED_FILTERS.map((filterKey) => {
			const filterValue = getFilterValues(searchObject, filterKey);
			return filterValue ? [filterKey, filterValue] : null;
		}).filter((entry): entry is [string, any] => entry !== null)
	) as Filters;
}
