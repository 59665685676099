import styled from "styled-components";
import { tokens } from "../../../styles/tokens";

// USAGE GUIDANCE

// Please choose the right component based on which of the following capabilties you want
// 1. It must look like the Fluent design styled link.
// 2. It is rendered as a true <a> tag with href attribute. This allows ctrl+click and other a11y scenarios.
// 3. It integrates with React Router. If the link points to a client side route, it should not trigger reload.
// 4. It must be re-stylable for font size/weight adjustment.

// If you want #1, #2, #3, #4  -> use this component
// If you want #1, #2, #3      -> use <FluentLink as={RouterLink} to="">
// If you want #1, #2          -> use <FluentLink href="">
// If you want #1,     #3      -> use <FluentLink onClick={() => mannuallyTriggerRouterNavigate()}>

export const RestylableLink = styled.a`
	color: ${(props) => props.theme.fluentUi.semanticColors.link};
	text-decoration: none;

	&:hover {
		color: ${(props) => props.theme.fluentUi.semanticColors.linkHovered};
		text-decoration: underline;
	}
`;

/**
 * Looks like a default (secondary) button, behaves like a link
 */
export const RestylableDefaultButtonLink = styled.a`
	font-size: 14px;
	font-weight: 600;
	color: ${(props) => props.theme.fluentUi.semanticColors.buttonText};
	border: 1px solid ${(props) => props.theme.fluentUi.semanticColors.buttonBorder};
	background-color: ${(props) => props.theme.fluentUi.semanticColors.buttonBackground};
	border-radius: ${tokens.corners.radius2};
	min-height: 32px;
	padding: 0 16px;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: ${(props) => props.theme.fluentUi.semanticColors.buttonTextHovered};
		background-color: ${(props) => props.theme.fluentUi.semanticColors.buttonBackgroundHovered};
	}
`;

/**
 * Looks like a primary button, behaves like a link
 */
export const RestylablePrimaryButtonLink = styled(RestylableDefaultButtonLink)`
	color: ${(props) => props.theme.fluentUi.semanticColors.primaryButtonText};
	border: 1px solid ${(props) => props.theme.fluentUi.semanticColors.primaryButtonBorder};
	background-color: ${(props) => props.theme.fluentUi.semanticColors.primaryButtonBackground};

	&:hover {
		color: ${(props) => props.theme.fluentUi.semanticColors.primaryButtonTextHovered};
		background-color: ${(props) => props.theme.fluentUi.semanticColors.primaryButtonBackgroundHovered};
	}
`;

/**
 * Looks like a link, behaves like a button
 */
export const RestylableLinkButton = styled.button`
	border: none;
	background: none;
	padding: 0;
	cursor: pointer;
	display: inline;
	color: ${(props) => props.theme.fluentUi.semanticColors.link};
	text-decoration: none;
	font-size: inherit;

	&:hover {
		color: ${(props) => props.theme.fluentUi.semanticColors.linkHovered};
		text-decoration: underline;
	}
`;
