export async function getBlobUrlFromFile(file: File): Promise<string> {
	const reader = new FileReader();
	reader.readAsDataURL(file);

	return new Promise((resolve) => {
		reader.onload = (e) => {
			resolve(e.target!.result!.toString());
		};
	});
}
