import { useEffect, useState } from "react";
import { useAuthContext } from "../contexts/auth-context";
import { RequestConfig } from "../utils/request";

const GRAPH_API_BASE_URL_V1 = "https://graph.microsoft.com/v1.0";

export function useGraphApi() {
	const authContext = useAuthContext();
	const [requestConfig, setRequestConfig] = useState<RequestConfig>();

	useEffect(() => {
		if (authContext.authState === "signed-in") {
			authContext.getMicrosoftGraphToken().then((accessToken) =>
				setRequestConfig({
					baseUrl: GRAPH_API_BASE_URL_V1,
					accessToken,
				})
			);
		}
	}, [authContext.authState]);

	return {
		requestConfig,
	};
}
