import { ApolloClient, from, HttpLink, InMemoryCache, Operation, ServerError, ServerParseError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { appInsights } from "./app-insights";
import { AuthService, authService } from "./auth-service";
import { ensureBaseUrl } from "./ensure-base-url";
import { env } from "./env";

export class HitsGraphQLRequestError extends Error {
	constructor(errorObject: GraphQLError) {
		super();
		this.name = "GraphQL Request Error";
		this.message = errorObject.message;
		this.stack = JSON.stringify(errorObject);
	}
}

export class HitsGraphQLNetworkError extends Error {
	constructor(errorObject: Error | ServerError | ServerParseError) {
		super();
		this.name = "GraphQL Network Error";
		this.message = errorObject.message;
		this.stack = JSON.stringify(errorObject);
	}
}

export class ApolloService {
	#client: ApolloClient<any>;
	get client() {
		return this.#client;
	}

	constructor(private authService: AuthService) {
		this.#client = this.getClient();
	}

	private getClient(): ApolloClient<any> {
		const asyncAuthLink = setContext(
			() =>
				new Promise(async (resolve) => {
					resolve({
						headers: {
							["hits-api-base-url"]: ensureBaseUrl(env.hitsGraphqlRestTarget),
							/** @deprecated, use `authorization` instead */
							["hits-api-authorization"]: `Bearer ${await this.authService.getHitsApiToken()}`,
							["authorization"]: `Bearer ${await this.authService.getHitsApiToken()}`,
							["microsoft-graph-authorization"]: `Bearer ${await this.authService.getMicrosoftGraphToken()}`,
						},
					});
				})
		);

		const httpLink = new HttpLink({
			uri: env.hitsGraphqlEndpoint,
		});

		const composeErrorInfo = (error: Error, operation: Operation) => {
			return {
				exception: error,
				properties: {
					queryName: operation?.operationName,
					queryParams: operation?.variables,
				},
			};
		};

		const errorReportingLink = onError(({ graphQLErrors, networkError, operation }) => {
			if (graphQLErrors) {
				graphQLErrors.map((graphQLError) => {
					appInsights.trackException(composeErrorInfo(new HitsGraphQLRequestError(graphQLError), operation));
				});
			}
			if (networkError) {
				appInsights.trackException(composeErrorInfo(new HitsGraphQLNetworkError(networkError), operation));
			}
		});

		const client = new ApolloClient({
			cache: createInMemoryCache(),
			link: from([errorReportingLink, asyncAuthLink, httpLink]),
		});

		return client;
	}
}

export function createInMemoryCache() {
	return new InMemoryCache({
		possibleTypes: {
			// ref: https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
			BaseLiteModel: ["LiteModel", "LiteModelWithMedia"],
		},
		typePolicies: {
			User: {
				// directoryObjectId is the source of truth for user identity. `id` field is null for users who are not in HITS db.
				// Users who are in HITS db but no longer exists in AAD will retain their directoryObjectId indefinitely
				keyFields: ["directoryObjectId"],
			},
			// Singleton types that have no identifying field can use an empty
			// array for their keyFields.
			// ref: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
			DiscoverInterests: {
				keyFields: [],
			},
			MyContent: {
				keyFields: [],
			},
		},
	});
}

/** Singleton: each app can only have a single apollo service */
export const apolloService = new ApolloService(authService);
