import { useQuery } from "@apollo/client";
import { MessageBar } from "@fluentui/react";
import { NotificationAudience } from "@hits/rest-api-types";
import React, { useState } from "react";
import { AllCommunications, ALL_COMMUNICATIONS } from "../../apis/queries/communications";

const ALLOWED_AUDIENCE: NotificationAudience[] = ["Users"];

// HACK message for `NonWDGUsers` audience contains special html that requires additional javascript from legacy UI to handle.
// We cannot show it here until there is a compatible format for both frontends

export const CommunicationMessage: React.FC = () => {
	const { data } = useQuery<AllCommunications>(ALL_COMMUNICATIONS);
	const [isVisible, setIsVisible] = useState(true); // TODO this should be remembered for each user with localStorage API

	// TODO currently, audience type is hard coded to just "Users". We need to determine this on the fly based the actual user's audience type
	const displayableMessage = data?.communications?.filter((message) => ALLOWED_AUDIENCE.includes(message.audience))?.[0];

	return displayableMessage && isVisible ? (
		<MessageBar aria-live="polite" onDismiss={() => setIsVisible(false)} dismissButtonAriaLabel="Close">
			<span dangerouslySetInnerHTML={{ __html: displayableMessage.message }}></span>
		</MessageBar>
	) : null;
};
